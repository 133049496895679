import { useCallback } from "react";
import moment from "moment";

function useListUpcomingEvents() {
  return useCallback(async (maxResults = 10) => {
    try {
      const req = {
        calendarId: "primary",
        timeMin: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZZ"),
        showDeleted: false,
        singleEvents: true,
        maxResults,
        orderBy: "startTime",
      };
      const res = await window.gapi.client.calendar?.events?.list(req);

      if (res && res.result) return res.result;
    } catch (e) {
      throw e;
    }
  });
}

function useListEventsByDate() {
  return useCallback(async (timeMin, maxResults = 10) => {
    try {
      const req = {
        calendarId: "primary",
        timeMin: moment(timeMin).format("YYYY-MM-DDTHH:mm:ssZZ"),
        showDeleted: false,
        singleEvents: true,
        maxResults,
        orderBy: "startTime",
      };
      const res = await window.gapi.client.calendar?.events?.list(req);

      if (res && res.result) return res.result;
    } catch (e) {
      throw e;
    }
  });
}

export { useListUpcomingEvents, useListEventsByDate };
