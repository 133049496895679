import { useState } from "react";
import { PropTypes } from "prop-types";
import {
  Box,
  Grid,
  Modal,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import Swal from "sweetalert2";
import { useMaterialUIController } from "context";
import { usePicker } from "hooks/usePickerAPI";
import {
  useReadRange,
  useGetSpreadsheet,
  useBatchWriteRange,
  useCreateSpreadsheet,
  useAddSheet,
} from "hooks/useSheetAPI";
import moment from "moment";
import { stringSimilarity } from "string-similarity-js";

function ExportModal({
  reconciled,
  attendees,
  exportAttendees,
  setExportAttendees,
  currentMeet,
  openModal,
  setOpenModal,
  filterType,
  startTime,
  endTime,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [exportType, setExportType] = useState("newsheet");
  const [existingSheet, setExistingSheet] = useState(null);
  const [spreadsheetSheets, setSpreadsheetSheets] = useState(null);
  const [selectedSpreadsheetSheet, setSelectedSpreadsheetSheet] = useState(null);
  const [threshold, setThreshold] = useState(0);
  const [emailColumn, setEmailColumn] = useState("A");
  const [nameColumn, setNameColumn] = useState("B");
  const [surnameColumn, setSurnameColumn] = useState("C");
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [exporting, setExporting] = useState(false);

  const showPicker = usePicker();
  const readRange = useReadRange();
  const writeRange = useBatchWriteRange();
  const addSheet = useAddSheet();
  const getSpreadsheet = useGetSpreadsheet();
  const createSpreadsheet = useCreateSpreadsheet();

  const formatDiffTime = (diff) => {
    const totalMinutes = parseInt(Math.floor(diff / 60), 0);
    let seconds = parseInt(diff % 60, 0);
    let minutes = parseInt(Math.floor(totalMinutes % 60), 0);
    let hours = parseInt(Math.floor(totalMinutes / 60), 0);

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${hours}:${minutes}`; //:${seconds}`;
  };

  const formatDate = (date) => {
    if (date) return moment(date).format("DD MMM YYYY HH:mm (ZZ)");

    return "";
  };

  const resetAll = () => {
    setExportType("newsheet");
    setExistingSheet(null);
    setSpreadsheetSheets(null);
    setSelectedSpreadsheetSheet(null);
    setEmailColumn("A");
    setNameColumn("B");
    setSurnameColumn("C");
    setSelectedColumn(null);
    setThreshold(0);
    setExporting(false);
    // setExportAttendees(false);
  };

  const getRandomSheetName = () => {
    const min = Math.ceil(1);
    const max = Math.floor(1000);
    const rand = Math.floor(Math.random() * (max - min) + min);

    if (exportAttendees) {
      return `Sheet-ATTENDEES-${rand}`;
    }

    const name =
      filterType.toUpperCase() === "UNKNOWN" ? "PRESENT-BUT-UNMATCHED" : filterType.toUpperCase();

    return `Sheet-${name}-${rand}`;
  };

  const getSpreadsheetSheets = async (doc) => {
    const sheet = existingSheet || doc;

    if (sheet && sheet.id) {
      const ss = await getSpreadsheet(sheet.id);
      const sheets = ss.result.sheets
        .map((sh) => {
          return {
            id: sh.properties.sheetId,
            index: sh.properties.index,
            type: sh.properties.sheetType,
            title: sh.properties.title,
          };
        })
        .filter((sh) => sh.type === "GRID");
      const renderSheets = sheets.map((sh) => (
        <MenuItem key={sh.id} value={JSON.stringify(sh)}>
          {sh.title}
        </MenuItem>
      ));
      setSpreadsheetSheets(renderSheets);
    }
  };

  const writeSheet = async (spreadsheetId, sheetName) => {
    if (exportAttendees) {
      const newValues = [["Name", /*"Enter Time", "Last Detected",*/ "Total time"]];

      attendees.forEach((item) => {
        const name = item.name.startsWith("+") ? `'${item.name}` : item.name;

        newValues.push([
          name,
          // formatDate(item.enterTime),
          // formatDate(item.lastDetected),
          formatDiffTime(item.blocksSeconds || item.totalSeconds),
        ]);
      });

      const newData = {
        range: `${sheetName}!A1:D`,
        values: newValues,
      };

      return await writeRange(spreadsheetId, newData);
    }

    const newValues = [["Name", "eMail", "Company"]];

    reconciled.forEach((item) => {
      const name = item.name.startsWith("+") ? `'${item.name}` : item.name;

      newValues.push([name, item.email, item.company]);
    });

    const newData = {
      range: `${sheetName}!A1:D`,
      values: newValues,
    };

    return await writeRange(spreadsheetId, newData);
  };

  const writeNew = async () => {
    const now = moment().format("DD-MM-YYYY HH:mm:ss");
    const name =
      filterType.toUpperCase() === "UNKNOWN" ? "PRESENT-BUT-UNMATCHED" : filterType.toUpperCase();
    let fileName = `${currentMeet.name}-${name}-exported-${now}`;

    if (exportAttendees) fileName = `${currentMeet.name}-ATTENDEE-exported-${now}`;

    const res = await createSpreadsheet(`${fileName}`);
    const spreadsheetId = res.result.spreadsheetId;
    const sheetName = res.result.sheets[0].properties.title;

    await writeSheet(spreadsheetId, sheetName);

    // window.open(res.result.spreadsheetUrl, "_blank");
    Swal.fire("", `Successfully exported list to "${fileName}"`, "success").then((result) => {
      window.open(res.result.spreadsheetUrl, "_blank");
    });
    handleCloseModal();
  };

  const writeExisting = async () => {
    const theSheet = JSON.parse(selectedSpreadsheetSheet);
    let sheetName = theSheet.title;

    if (sheetName === "mtaa-newsheet") {
      sheetName = getRandomSheetName();

      await addSheet(existingSheet.id, sheetName);
      await writeSheet(existingSheet.id, sheetName);

      // window.open(existingSheet.url, "_blank");
      Swal.fire(
        "",
        `Successfully exported list to "${existingSheet.name}", sheet "${sheetName}"`,
        "success"
      ).then((result) => {
        window.open(existingSheet.url, "_blank");
      });
      handleCloseModal();
    } else {
      const result = await Swal.fire({
        icon: "info",
        html: `
          <p>You’re about overwrite the content of the sheet <strong>${sheetName}</strong></p>
          <br /><br />
          <p><strong>Are you sure?</strong></p>
        `,
        showCancelButton: true,
      });

      if (result.isConfirmed) {
        await writeSheet(existingSheet.id, sheetName);

        // window.open(existingSheet.url, "_blank");
        Swal.fire(
          "",
          `Successfully exported list to "${existingSheet.name}", sheet "${sheetName}"`,
          "success"
        ).then((result) => {
          window.open(existingSheet.url, "_blank");
        });
        handleCloseModal();
      }
    }
  };

  const compareNames = (origName, name, altName = null) => {
    const similName = altName
      ? stringSimilarity(origName.toLowerCase(), altName.toLowerCase())
      : stringSimilarity(origName.toLowerCase(), name.toLowerCase());
    // const similAltName = altName
    //   ? stringSimilarity(origName.toLowerCase(), altName.toLowerCase())
    //   : 0;

    return similName >= 0.8; // || similAltName >= 0.8;
  };

  const writeGHA = async () => {
    const startingRow = 4;
    const theSheet = JSON.parse(selectedSpreadsheetSheet);
    const cols = [
      emailColumn.toUpperCase(),
      nameColumn.toUpperCase(),
      surnameColumn.toUpperCase(),
    ].sort();
    const firstCol = cols[0];
    const lastCol = cols.slice(-1)[0];
    const startingLetter = firstCol.charCodeAt(0);
    const totalCols = lastCol.charCodeAt(0) - startingLetter + 1;
    const allCols = Array.from(Array(totalCols)).map((e, i) =>
      String.fromCharCode(i + startingLetter)
    );
    const emailIdx = allCols.indexOf(emailColumn);
    const nameIdx = allCols.indexOf(nameColumn);
    const surnameIdx = allCols.indexOf(surnameColumn);
    const res = await readRange(
      existingSheet.id,
      `${theSheet.title}!${firstCol}${startingRow}:${lastCol}`
    );
    const rows = res.result.values.map((row) => {
      return {
        name: `${row[nameIdx]?.trim()} ${row[surnameIdx]?.trim() || ""}`.trim(),
        email: row[emailIdx]?.trim(),
      };
    });
    const found = [];
    const updateValues = rows.map((row) => {
      const attendee = reconciled.find(
        (att) => att.email === row.email // || compareNames(row.name, att.name.trim(), att.is?.name.trim())
      );

      if (attendee) {
        const sessionTime = endTime.diff(startTime, "seconds");
        const inCallPercentile = (attendee.blocksSeconds / sessionTime) * 100;

        found.push(attendee.email);

        if (inCallPercentile >= threshold) return ["Yes"];
        else return ["No"];
      } else return [""];
    });
    const updates = {
      range: `${theSheet.title}!${selectedColumn}${startingRow}:${selectedColumn}`,
      values: updateValues,
    };

    await writeRange(existingSheet.id, updates);

    let type = "success";
    let message = `<p>Successfully exported list to "${existingSheet.name}", sheet "${theSheet.title}"</p>`;
    const notFound = reconciled.filter((r) => !found.includes(r.email));
    const nfTotal = notFound.length;

    if (nfTotal > 0) {
      type = "warning";
      message += `<br /><hr /><br />
        <h3 class="swal2-title" style="display: block; color: palevioletred;">ALERT: There ${
          nfTotal === 1 ? "is" : "are"
        } ${nfTotal} missing name${
        nfTotal > 1 ? "s" : ""
      } on the GHA Tracker sheet you are trying to update. The following name${
        nfTotal > 1 ? "s" : ""
      } ${nfTotal === 1 ? "is" : "are"} missing:</h3>
        <br><ul style="width: 100%; height: 100px; overflow: auto">`;
      message += notFound.map((nf) => `<li>${nf.name}</li>`).join("");
      message += "</ul>";
    }

    Swal.fire("", message, type).then((result) => {
      // window.open(existingSheet.url, "_blank");
    });
    handleCloseModal();
  };

  const handleExporTypeChange = (e) => {
    const value = e.target.value;

    resetAll();
    setExportType(value);
  };

  const handleOpenExistingSheet = (e) => {
    showPicker((data) => {
      if (data && data.action === "picked") {
        const doc = data.docs[0];

        setExistingSheet(doc);
        getSpreadsheetSheets(doc);
      }
    });
  };

  const handleSpreadsheetSheetChange = (e) => {
    let sheet = e.target.value;
    console.log(sheet);
    setSelectedSpreadsheetSheet(sheet);
  };

  const handleThresholdChange = (e) => {
    try {
      if (!e.target.value) setThreshold(0);
      else {
        let value = parseInt(e.target.value);

        if (value >= 100) value = 100;
        if (value <= 0) value = 0;

        setThreshold(value);
      }
    } catch (e) {
      setThreshold(80);
    }
  };

  const isValidSheetColumn = (value) => {
    return value
      ? /^[A-Z]{1,3}$/.test(value.toUpperCase()) &&
          (value.length === 3 ? value.toUpperCase() <= "XFD" : true)
      : true;
  };

  const handleEmailColumnChange = (e) => {
    const value = e.target.value;

    if (!isValidSheetColumn(value)) return false;

    setEmailColumn(value);
  };

  const handleNameColumnChange = (e) => {
    const value = e.target.value;

    if (!isValidSheetColumn(value)) return false;

    setNameColumn(value);
  };

  const handleSurnameColumnChange = (e) => {
    const value = e.target.value;

    if (!isValidSheetColumn(value)) return false;

    setSurnameColumn(value);
  };

  const handleColumnChange = (e) => {
    const value = e.target.value;

    if (!isValidSheetColumn(value)) return false;

    setSelectedColumn(value);
  };

  const handleExport = async (e) => {
    if (exportType !== "newsheet") {
      if (!existingSheet) {
        setOpenModal(false);
        Swal.fire("", "Please select a spreadsheet", "warning").then(() => setOpenModal(true));

        return;
      }

      if (!selectedSpreadsheetSheet) {
        setOpenModal(false);
        Swal.fire("", "Please select a sheet", "warning").then(() => setOpenModal(true));

        return;
      }

      if (exportType === "gha") {
        if (!emailColumn) {
          setOpenModal(false);
          Swal.fire("", "Please input the email column", "warning").then(() => setOpenModal(true));

          return;
        }

        if (!nameColumn) {
          setOpenModal(false);
          Swal.fire("", "Please input the first name column", "warning").then(() =>
            setOpenModal(true)
          );

          return;
        }

        if (!surnameColumn) {
          setOpenModal(false);
          Swal.fire("", "Please input the surname column", "warning").then(() =>
            setOpenModal(true)
          );

          return;
        }

        if (!selectedColumn) {
          setOpenModal(false);
          Swal.fire("", "Please input the column to update", "warning").then(() =>
            setOpenModal(true)
          );

          return;
        }
      }
    }

    setExporting(true);

    if (exportType === "existing") {
      await writeExisting();
    } else if (exportType === "gha") {
      await writeGHA();
    } else {
      await writeNew();
    }

    setExporting(false);
  };

  const handleCloseModal = () => {
    // resetAll();
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-export-title"
      aria-describedby="modal-export-escription"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: "15px",
        }}
      >
        <MDBox mb={1}>
          <MDTypography id="modal-export-title" variant="h6" component="h2" fontWeight="medium">
            Export list of those {filterType === "unknown" ? "present but unmatched" : filterType}{" "}
            ...
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12} style={{ display: "flex" }}>
              <ToggleButtonGroup
                size="small"
                exclusive
                color={sidenavColor}
                value={exportType}
                name="export-type-group"
                defaultValue="newsheet"
                onChange={handleExporTypeChange}
                style={{ margin: "auto" }}
              >
                <ToggleButton value="newsheet">New Sheet</ToggleButton>
                <ToggleButton value="existing">Existing Sheet</ToggleButton>
                {!exportAttendees && filterType === "present" && (
                  <ToggleButton value="gha">GHA specific</ToggleButton>
                )}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {(exportType === "gha" || exportType === "existing") && (
                <MDBox mb={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={5} lg={5}>
                      <MDButton
                        component="button"
                        variant="outlined"
                        color="secondary"
                        onClick={handleOpenExistingSheet}
                        style={{ margin: "8px 0 0 8px" }}
                      >
                        <FileOpenOutlinedIcon style={{ width: 30, height: 30 }} />
                        &nbsp;Select a file
                      </MDButton>
                      {/* <MDButton onClick={handleOpenExistingSheet}>
                        <FileOpenOutlinedIcon size="small" />
                      </MDButton> */}
                    </Grid>
                    <Grid item xs={12} md={7} lg={7}>
                      <MDTypography
                        display="block"
                        variant="button"
                        fontWeight="medium"
                        style={{ marginTop: 10 }}
                      >
                        {existingSheet?.name}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl sx={{ m: 1, width: "93%" }}>
                        <InputLabel id="select-sheet-label">Select sheet</InputLabel>
                        <Select
                          labelId="select-sheet-label"
                          id="select-sheet"
                          label="Select sheet"
                          onChange={handleSpreadsheetSheetChange}
                          autoWidth
                          value={selectedSpreadsheetSheet}
                          sx={{ minHeight: 45 }}
                        >
                          {exportType === "existing" && (
                            <MenuItem
                              key={Number.MAX_SAFE_INTEGER}
                              value={JSON.stringify({
                                id: Number.MAX_SAFE_INTEGER,
                                index: 0,
                                type: "GRID",
                                title: "mtaa-newsheet",
                              })}
                            >
                              New sheet
                            </MenuItem>
                          )}
                          {spreadsheetSheets}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* {exportType === "gha" && (
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl sx={{ m: 1, width: "93%" }}>
                          <MDInput
                            label="Attendee duration threshold %"
                            value={threshold}
                            // type="number"
                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                            onChange={handleThresholdChange}
                          />
                        </FormControl>
                      </Grid>
                    )} */}
                    {exportType === "gha" && (
                      <Grid item xs={12} md={12} lg={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl sx={{ m: 1, width: "93%" }}>
                              <MDInput
                                label="Email column"
                                value={emailColumn}
                                onChange={handleEmailColumnChange}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl sx={{ m: 1, width: "93%" }}>
                              <MDInput
                                label="First Name column"
                                value={nameColumn}
                                onChange={handleNameColumnChange}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl sx={{ m: 1, width: "93%" }}>
                              <MDInput
                                label="Surname column"
                                value={surnameColumn}
                                onChange={handleSurnameColumnChange}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl sx={{ m: 1, width: "93%" }}>
                              <MDInput
                                label="Column to update"
                                value={selectedColumn}
                                onChange={handleColumnChange}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <MDButton
              component="button"
              onClick={handleCloseModal}
              variant="gradient"
              color="secondary"
              fullWidth
            >
              Cancel
            </MDButton>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDButton
              component="button"
              onClick={handleExport}
              variant="gradient"
              color={sidenavColor}
              disabled={exporting}
              fullWidth
            >
              Export
            </MDButton>
          </Grid>
        </Grid>
        {exporting && (
          <CircularProgress
            size={24}
            sx={{
              color: "green",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    </Modal>
  );
}

ExportModal.propTypes = {
  reconciled: PropTypes.array,
  attendees: PropTypes.array,
  exportAttendees: PropTypes.bool,
  setExportAttendees: PropTypes.func,
  currentMeet: PropTypes.object,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  filterType: PropTypes.string,
  startTime: PropTypes.object,
  endTime: PropTypes.object,
};

export { ExportModal };
