function loadScript(src, callback) {
  const tag = document.createElement("script");

  tag.setAttribute("async", true);
  tag.setAttribute("defer", true);
  tag.src = src;
  tag.onload = callback;

  document.body.appendChild(tag);
}

export { loadScript };
