import moment from "moment";

async function isLoggedIn() {
  const res = await fetch(`${process.env.REACT_APP_COUCHDB_URL}/_session`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();

  return data && data.ok && data.userCtx?.name !== null;
}

async function loginCouchDb() {
  const loggedIn = await isLoggedIn();

  if (!loggedIn) {
    // const res = await fetch(`${process.env.REACT_APP_COUCHDB_URL}/_session`, {
    const res = await fetch(`/db/_session`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: "admin",
        password: process.env.REACT_APP_COUCHDB_PWD,
      }),
    });

    const data = await res.json();

    return data && data.ok && data.name !== null;
  }

  return loggedIn;
}

const zeroPad = (num, places) => String(num).padStart(places, "0");

async function getCalendar() {
  const res = await fetch(
    `/db/${process.env.REACT_APP_COUCHDB_NAME}/_design/app/_view/by_date?group_level=3`,
    {
      method: "GET",
      credentials: "include",
    }
  );

  if (res.status >= 200 && res.status <= 302) {
    const data = await res.json();

    if (data && data.rows.length > 0) {
      let calendar = {};

      for (const row of data.rows) {
        const date = `${row.key[0]}/${zeroPad(row.key[1], 2)}/${zeroPad(row.key[2], 2)}`;

        calendar[date] = row.value;
      }

      return calendar || null;
    }
  }

  return null;
}

async function getDocuments() {
  const res = await fetch(
    // `${process.env.REACT_APP_COUCHDB_URL}/${process.env.REACT_APP_COUCHDB_NAME}/_design/app/_view/by_id`,
    `/db/${process.env.REACT_APP_COUCHDB_NAME}/_design/app/_view/by_id`,
    {
      method: "GET",
      credentials: "include",
    }
  );

  if (res.status >= 200 && res.status <= 302) {
    const data = await res.json();

    if (data && data.rows.length > 0) return data.rows;
  }

  return [];
}

async function getDocumentById(id) {
  const res = await fetch(
    // `${process.env.REACT_APP_COUCHDB_URL}/${process.env.REACT_APP_COUCHDB_NAME}/${id}?latest=true`,
    `/db/${process.env.REACT_APP_COUCHDB_NAME}/${id}?latest=true`,
    {
      method: "GET",
      credentials: "include",
    }
  );

  if (res.status >= 200 && res.status <= 302) {
    const data = await res.json();

    if (data) return data;
  }

  return {};
}

async function getLinks(id) {
  const doc = await getDocumentById(`links-${id}`);

  if (doc) return doc.links;

  return undefined;
}

async function saveLinks(id, links) {
  const doc = await getDocumentById(`links-${id}`);

  if (!doc?._id) {
    doc._id = `links-${id}`;
  }

  doc.links = links;

  const res = await fetch(`/db/${process.env.REACT_APP_COUCHDB_NAME}`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(doc),
  });

  if (res.status >= 200 && res.status <= 302) {
    const data = await res.json();

    if (data) doc._rev = data.rev;

    return true;
  }

  return false;
}

function subscribeDocChanges(id, callback) {
  if (!id) return null;

  const sse = new EventSource(
    // `${process.env.REACT_APP_COUCHDB_URL}/${process.env.REACT_APP_COUCHDB_NAME}/_changes?feed=eventsource&include_docs=true&filter=app/by_id&meetId=${id}`,
    `/db/${process.env.REACT_APP_COUCHDB_NAME}/_changes?feed=eventsource&include_docs=true&filter=app/by_id&meetId=${id}`,
    {
      withCredentials: true,
    }
  );

  sse.onmessage = (e) => callback(JSON.parse(e.data));
  sse.onerror = (e) => {
    console.error("SSE", e);
    sse.close();
  };

  return sse;
}

export {
  isLoggedIn,
  loginCouchDb,
  getCalendar,
  getDocuments,
  getDocumentById,
  subscribeDocChanges,
  getLinks,
  saveLinks,
};
