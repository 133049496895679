import { stringSimilarity } from "string-similarity-js";

const THRESHOLD = 0.8;
const MED_THRESHOLD = 0.7;
const LOW_THRESHOLD = 0.6;
const LOW_LENGTH = 7;
const MED_LENGTH = 11;

const compareNames = (origName, name) => {
  if (!origName || !name) return false;

  const similName = stringSimilarity(origName.trim().toLowerCase(), name.trim().toLowerCase());

  if (similName < THRESHOLD) {
    const origNameParts = origName
      .trim()
      .toLowerCase()
      .replaceAll("-", " ")
      .replaceAll("'", "")
      .replaceAll("’", "")
      .split(" ");
    const nameParts = name
      .trim()
      .toLowerCase()
      .replaceAll("-", " ")
      .replaceAll("'", "")
      .replaceAll("’", "")
      .split(" ");

    if (origNameParts.length >= nameParts.length) {
      const intersection = origNameParts.filter((on) =>
        nameParts.find((n) => {
          const threshold =
            on.length < LOW_LENGTH
              ? LOW_THRESHOLD
              : on.length < MED_LENGTH
              ? MED_THRESHOLD
              : THRESHOLD;

          return stringSimilarity(on, n) >= threshold;
        })
      );

      if (origNameParts.length === nameParts.length) {
        return intersection.length === origNameParts.length;
      }

      return intersection.length >= origNameParts.length;
      // return intersection.length >= origNameParts.length - 1;
    } else {
      const intersection = nameParts.filter((n) =>
        origNameParts.find((on) => {
          const threshold =
            on.length < LOW_LENGTH
              ? LOW_THRESHOLD
              : on.length < MED_LENGTH
              ? MED_THRESHOLD
              : THRESHOLD;

          return stringSimilarity(on, n) >= threshold;
        })
      );

      return intersection.length >= nameParts.length;
      // return intersection.length >= nameParts.length - 1;
    }
  }

  return similName >= THRESHOLD;
};

const compareNamesScore = (origName, name) => {
  if (!origName || !name) return { score: 0, threshold: THRESHOLD, origName, name };

  const similName = stringSimilarity(origName.trim().toLowerCase(), name.trim().toLowerCase());

  if (similName >= THRESHOLD) {
    return { score: similName, threshold: THRESHOLD, origName, name };
  } else {
    const origNameParts = origName
      .trim()
      .toLowerCase()
      .replaceAll("-", " ")
      .replaceAll("'", "")
      .replaceAll("’", "")
      .split(" ");
    const nameParts = name
      .trim()
      .toLowerCase()
      .replaceAll("-", " ")
      .replaceAll("'", "")
      .replaceAll("’", "")
      .split(" ");

    if (origNameParts.length === 1 || nameParts.length === 1)
      return { score: 0, threshold: THRESHOLD, origName, name };

    const scores = origNameParts
      .map((on) => {
        const threshold =
          on.length < LOW_LENGTH
            ? LOW_THRESHOLD
            : on.length < MED_LENGTH
            ? MED_THRESHOLD
            : THRESHOLD;
        const result = nameParts.reduce((filtered, n) => {
          const score = stringSimilarity(on, n);

          if (score >= threshold) filtered.push({ score, threshold });

          return filtered;
        }, []);

        return result.length > 0 ? result[0] : { score: 0, threshold };
      })
      .filter((s) => s != null);

    if (scores.length > 0) {
      const sums = scores.reduce((acc, current) => {
        if (!acc) return current;

        return {
          score: acc.score + current.score,
          threshold: acc.threshold + current.threshold,
        };
      }, null);

      return {
        score: sums.score / scores.length,
        threshold: sums.threshold / scores.length,
        origName,
        name,
      };
    }
  }

  return { score: 0, threshold: THRESHOLD, origName, name };
};

export { compareNames, compareNamesScore };
