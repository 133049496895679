import { useCallback } from "react";

function useGetTemplate() {
  return useCallback(async (templateId) => {
    try {
      const result = await fetch(`/template/${templateId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Postmark-Server-Token": process.env.REACT_APP_POSTMARK_TOKEN,
        },
      });
      return await result.json();
    } catch (e) {
      throw e;
    }
  });
}

export { useGetTemplate };
