import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import {
  Box,
  Grid,
  Modal,
  Autocomplete,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Checkbox,
  ListItemIcon,
  ListSubheader,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { getLinks } from "lib/couchDb";
import MDBox from "components/MDBox";
import Swal from "sweetalert2";

function LinkModal({ meetId, items, linkedItems, absentees, updateData, openModal, setOpenModal }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [selectedAbsent, setSelectedAbsent] = useState(null);
  const [sortedAbsentees, setSortedAbsentees] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortedItems, setSortedItems] = useState([]);
  const [localLinkedItems, setLocalLinkedItems] = useState(linkedItems);
  const [linkEnabled, setLinkEnabled] = useState(false);

  const resetAll = () => {
    setSelectedAbsent(null);
    setSelectedItem(null);
    setLocalLinkedItems(linkedItems);
    setLinkEnabled(false);
  };

  const handleLink = () => {
    const links = { ...localLinkedItems };
    const keys = Object.keys(links);

    if (!keys.includes(selectedAbsent.email)) {
      links[selectedAbsent.email] = { ...selectedAbsent };
    }

    links[selectedAbsent.email].unknown = { ...selectedItem };
    setLocalLinkedItems(links);
    setSelectedAbsent(null);
    setSelectedItem(null);
  };

  const handleUnlink = (key) => {
    return (
      <MDButton
        onClick={() => {
          const links = { ...localLinkedItems };
          const keys = Object.keys(links);

          if (keys.includes(key)) {
            delete links[key];
          }

          setLocalLinkedItems(links);
        }}
      >
        <LinkOffIcon size="small" />
      </MDButton>
    );
  };

  const handleSave = () => {
    updateData(localLinkedItems);
    resetAll();
    setOpenModal(false);
    // Swal.fire("Linked!", `${item.name} linked with ${absent.name}`, "success");
  };

  const handleClose = () => {
    resetAll();
    setOpenModal(false);
  };

  // const loadLinks = async () => {
  //   debugger;
  //   if (meetId && !linkedItems) {
  //     const linksId = meetId.split("-").slice(1).join("-");
  //     const links = await getLinks(linksId);

  //     setLocalLinkedItems(links);
  //   }
  // };

  useEffect(() => {
    const list = absentees
      ?.map((absent) => {
        absent.label = absent.name;

        const extra = [];

        if (absent.phone) {
          if (absent.company.length + absent.phone.length >= 29) {
            let max = 29 - absent.phone.length - 7;

            if (max <= 0) max = 5;

            extra.push(`${absent.company.substr(0, max).trim()} ...`);
          } else extra.push(absent.company);

          extra.push(absent.phone);
        } else extra.push(absent.company);

        absent.extra = extra.join(" | ");

        return absent;
      })
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

        return 0;
      });

    setSortedAbsentees(list);
  }, [absentees]);

  useEffect(() => {
    const list = items
      ?.map((item) => {
        item.label = item.name;

        return item;
      })
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

        return 0;
      });

    setSortedItems(list);
  }, [items]);

  useEffect(() => {
    setLocalLinkedItems(linkedItems);
  }, [linkedItems]);

  useEffect(() => {
    if (selectedAbsent && selectedItem) {
      setLinkEnabled(true);
    } else {
      setLinkEnabled(false);
    }
  }, [selectedAbsent, selectedItem]);

  // useEffect(() => {
  //   loadLinks();
  // }, []);

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: "15px",
        }}
      >
        <MDBox mb={1}>
          <MDTypography id="modal-title" variant="h6" component="h2" fontWeight="medium">
            {`Link up to ${items?.length} present but unmatched with any of the ${sortedAbsentees?.length} absentees`}
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <Autocomplete
            disablePortal
            options={sortedItems}
            value={selectedItem}
            onChange={(e, value) => {
              setSelectedItem(value);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select from present but unmatched" />
            )}
            renderOption={(props, option) => <Box {...props}>{option.label}</Box>}
          />
        </MDBox>
        <MDBox mb={2}>
          <Autocomplete
            disablePortal
            options={sortedAbsentees}
            value={selectedAbsent}
            onChange={(e, value) => {
              setSelectedAbsent(value);
            }}
            renderInput={(params) => <TextField {...params} label="Select from absentees" />}
            renderOption={(props, option) => (
              <Box {...props}>
                {option.label}
                <br /> {option.email}
                <br /> {option.extra}
              </Box>
            )}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDButton
            component="button"
            onClick={handleLink}
            variant="gradient"
            color="secondary"
            fullWidth
            disabled={!linkEnabled}
          >
            <LinkIcon size="medium" />
          </MDButton>
        </MDBox>
        {localLinkedItems && (
          <MDBox mb={2}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                borderRadius: "0.75rem",
                maxHeight: "250px",
                overflow: "auto",
              }}
            >
              {Object.keys(localLinkedItems).map((key) => (
                <ListItem
                  id={key}
                  key={key}
                  alignItems="flex-start"
                  secondaryAction={handleUnlink(key)}
                  sx={{ borderBottom: "0.0625rem solid #f0f2f5" }}
                  data-item={JSON.stringify(localLinkedItems[key])}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={localLinkedItems[key].unknown.name}
                      src={localLinkedItems[key].unknown.picture}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={localLinkedItems[key].unknown.name}
                    secondary={localLinkedItems[key].name}
                  />
                </ListItem>
              ))}
            </List>
          </MDBox>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <MDButton
              component="button"
              onClick={handleClose}
              variant="gradient"
              color="secondary"
              fullWidth
            >
              Cancel
            </MDButton>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDButton
              component="button"
              onClick={handleSave}
              variant="gradient"
              color={sidenavColor}
              fullWidth
            >
              <SaveIcon size="small" />
            </MDButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

LinkModal.propTypes = {
  meetId: PropTypes.string,
  enrolled: PropTypes.array,
  updateData: PropTypes.func,
  items: PropTypes.array,
  setLinkedItems: PropTypes.func,
  linkedItems: PropTypes.object,
  absentees: PropTypes.array,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};

export { LinkModal };
