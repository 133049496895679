/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { loadScript } from "lib/loadScript";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const SCOPES = [
  "email",
  "profile",
  "openid",
  "https://www.googleapis.com/auth/spreadsheets",
  "https://www.googleapis.com/auth/drive",
  "https://www.googleapis.com/auth/drive.metadata",
  "https://www.googleapis.com/auth/drive.appdata",
  "https://www.googleapis.com/auth/drive.file",
  "https://www.googleapis.com/auth/calendar.readonly",
];
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
  "https://sheets.googleapis.com/$discovery/rest?version=v4",
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];

const initializeGapiClient = async () => {
  await window.gapi.client.init({
    apiKey: process.env.REACT_APP_GC_API_KEY,
    discoveryDocs: DISCOVERY_DOCS,
  });
  await window.gapi.client.load("drive", "v3");
  await window.gapi.client.load("sheets", "v4");
  await window.gapi.client.load("calendar", "v3");
  // await window.gapi.client.load("picker", "v1");
};

loadScript("https://apis.google.com/js/api.js", () => {
  window.gapi.load("client", initializeGapiClient);
  window.gapi.load("picker", () => {
    console.log("picker loaded!");
  });
});
loadScript("https://accounts.google.com/gsi/client", () => {
  window.tokenClient = window.google.accounts.oauth2.initTokenClient({
    client_id: process.env.REACT_APP_GC_CLIENT_ID,
    scope: SCOPES.join(" "),
    callback: "",
  });
});

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <App />
      </LocalizationProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
