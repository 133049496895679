import { useCallback } from "react";

function usePicker() {
  return useCallback(async (pickerCallback) => {
    const view = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS).setMode(
      window.google.picker.DocsViewMode.LIST
    );

    view.setMimeTypes("application/vnd.google-apps.spreadsheet");

    const picker = new window.google.picker.PickerBuilder()
      .setDeveloperKey(process.env.REACT_APP_GC_API_KEY)
      .setAppId(process.env.REACT_APP_GC_APP_ID)
      .setOAuthToken(window.gapi.client?.getToken()?.access_token)
      .addView(view)
      .addView(new window.google.picker.DocsUploadView())
      .setCallback(pickerCallback ? pickerCallback : (data) => console.log(data))
      .build();

    picker.setVisible(true);

    // document.querySelector(".picker-dialog")
    // document.querySelector(".picker-dialog-bg").style
    // console.log(document.querySelector(".picker-dialog"));
  });
}

export { usePicker };
