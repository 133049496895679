import { useCallback } from "react";

function useReadRange() {
  return useCallback(async (id, range) => {
    try {
      return await window.gapi.client.sheets?.spreadsheets?.values?.get({
        spreadsheetId: id,
        range: range,
      });
    } catch (e) {
      throw e;
    }
  });
}

function useWriteRange() {
  return useCallback(async (id, range, valueInputOption, _values) => {
    try {
      return await window.gapi.client.sheets?.spreadsheets?.values?.update({
        spreadsheetId: id,
        range,
        valueInputOption,
        resource: { values: _values },
      });
    } catch (e) {
      throw e;
    }
  });
}

function useBatchWriteRange() {
  return useCallback(async (id, updates) => {
    try {
      return await window.gapi.client.sheets?.spreadsheets?.values?.batchUpdate({
        spreadsheetId: id,
        resource: { data: updates, valueInputOption: "USER_ENTERED" },
      });
    } catch (e) {
      throw e;
    }
  });
}

function useAddSheet() {
  return useCallback(async (id, title) => {
    try {
      return await window.gapi.client.sheets?.spreadsheets?.batchUpdate({
        spreadsheetId: id,
        resource: { requests: [{ addSheet: { properties: { title: title } } }] },
      });
    } catch (e) {
      throw e;
    }
  });
}

function useCreateSpreadsheet() {
  return useCallback(async (title) => {
    try {
      return await window.gapi.client.sheets?.spreadsheets?.create({
        properties: {
          title: title,
        },
      });
    } catch (e) {
      throw e;
    }
  });
}

function useGetSpreadsheet() {
  return useCallback(async (id) => {
    try {
      return await window.gapi.client.sheets.spreadsheets.get({
        spreadsheetId: id,
        range: [],
        includeGridData: false,
      });
    } catch (e) {
      throw e;
    }
  });
}

export {
  useReadRange,
  useWriteRange,
  useBatchWriteRange,
  useCreateSpreadsheet,
  useGetSpreadsheet,
  useAddSheet,
};
