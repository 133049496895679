import { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Box, Grid, Modal, FormControl, MenuItem, Autocomplete, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import { useListUpcomingEvents, useListEventsByDate } from "hooks/useCalendarAPI";

function CalendarModal({ setSession, openModal, setOpenModal }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const listUpcomingEvents = useListUpcomingEvents();
  const listEventsByDate = useListEventsByDate();

  const parseEvents = (events) => {
    const cnt = [
      <MenuItem key="empty" value={{}}>
        None
      </MenuItem>,
    ];
    const evts = [];

    if (events.length > 0) {
      events.forEach((evt) => {
        evt.name = evt.summary;
        evt.label = evt.summary;
        cnt.push(
          <MenuItem key={evt.id} value={evt}>
            {`${evt.name} (${evt.id})`}
          </MenuItem>
        );
        evts.push(evt);
      });

      setEvents(evts);
    }
  };

  const getEvents = async () => {
    const events = await listUpcomingEvents(10);
    console.log(events);
    if (events) parseEvents(events.items);
  };

  const handleDateChange = async (value) => {
    setSelectedDate(value);

    if (value) {
      const events = await listEventsByDate(value, 10);

      if (events) parseEvents(events.items);
    } else {
      setEvents([]);
    }
  };

  const handleEventChange = (e) => {
    console.log(e);
  };

  const handleResetForm = () => {
    setEvents([]);
    setSelectedEvent(null);
  };

  const handleSelectEvent = (e) => {
    setSelectedEvent(selectedMeet);
    setOpenModal(false);
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <Modal
      open={openModal}
      onClose={() => {
        handleResetForm();
        setOpenModal(false);
      }}
      aria-labelledby="modal-meet-title"
      aria-describedby="modal-meet-escription"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: "15px",
        }}
      >
        <MDBox mb={1}>
          <MDTypography id="modal-meet-title" variant="h6" component="h2" fontWeight="medium">
            Select a Calendar event
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <FormControl sx={{ width: "100%" }}>
                <DatePicker
                  value={selectedDate}
                  onChange={handleDateChange}
                  slotProps={{ textField: { size: "medium" } }}
                  label="Event Date"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Autocomplete
                disablePortal
                options={events}
                value={selectedEvent}
                onChange={handleEventChange}
                renderInput={(params) => <TextField {...params} label="Event Name" />}
              />
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <MDButton
              component="button"
              onClick={handleResetForm}
              variant="gradient"
              color="primary"
              fullWidth
            >
              Clear
            </MDButton>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDButton
              component="button"
              onClick={() => {
                handleResetForm();
                setOpenModal(false);
              }}
              variant="gradient"
              color="secondary"
              fullWidth
            >
              Cancel
            </MDButton>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDButton
              component="button"
              onClick={handleSelectEvent}
              variant="gradient"
              color={sidenavColor}
              fullWidth
            >
              OK
            </MDButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

CalendarModal.propTypes = {
  setSession: PropTypes.func,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};

export { CalendarModal };
