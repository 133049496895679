import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import {
  Box,
  Grid,
  Modal,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { RTEditor } from "components/RTEditor";
import Swal from "sweetalert2";
import { useMaterialUIController } from "context";
import {
  sendMail,
  sendMailBatch,
  sendMailTemplate,
  sendMailTemplateBatch,
  sendMailBatchArray,
} from "lib/sendMail";
import Mustache from "mustache";
import { useGetTemplate } from "hooks/usePostmarkAPI";

function MailModal({ recipients, openModal, setOpenModal, clearSelected, selectedSender, meetId }) {
  const getTemplate = useGetTemplate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [mailType, setMailType] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateData, setTemplateData] = useState(null);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [sendDisabled, setSendDisabled] = useState(true);

  const resetAll = () => {
    setSelectedTemplate(null);
    setSubject("");
    setBody("");
    setSendDisabled(true);
    setTemplateData(null);
  };

  const buildLink = () => {
    let parts = meetId.split("-");

    parts.shift();

    const id = parts.join("-");

    return `https://meet.google.com/${id}`;
  };

  const parseRecipients = () => {
    const link = buildLink();

    return recipients.map((r) => {
      r.meetLink = link;

      if (r.is) {
        r.origName = r.name;
        r.name = r.is.name;
      }

      return r;
    });
  };

  const getBody = (str) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(str, "text/html");

    return html.getElementsByTagName("body")[0].innerHTML;
  };

  const handleMailTypeChange = (e) => {
    const value = e.target.value;

    setMailType(value);
    resetAll();
  };

  const handleSelectedTemplateChange = async (e) => {
    const value = e.target.value;
    const data = await getTemplate(value);

    setSelectedTemplate(value);
    setTemplateData(data);
    setBody(getBody(data.HtmlBody));
    setSendDisabled(false);
  };

  const handleSubjectChange = (e) => {
    const value = e.target.value;

    setSubject(value);

    if (body) setSendDisabled(false);
    else setSendDisabled(true);
  };

  const handleBodyChange = (data) => {
    setBody(data);

    if (subject) setSendDisabled(false);
    else setSendDisabled(true);
  };

  const handleSend = async () => {
    if (recipients && recipients.length > 0) {
      if (mailType === "template") {
        if (!selectedTemplate) {
          Swal.fire("", "Please choose an email template", "warning");

          return;
        }
      } else if (mailType === "freetext") {
        if (!subject || !body) {
          Swal.fire("", "Please fill in the email subject and body", "warning");

          return;
        }
      } else {
        Swal.fire("", "Please select an email type", "info");

        return;
      }

      setOpenModal(false);

      Swal.fire({
        icon: "info",
        html: `
          <p>You’re about to send an email to <strong>${recipients.length}</strong> recipient(s)</p>
          <br /><br />
          <p><strong>Are you sure?</strong></p>
        `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let result = null;

          if (selectedTemplate) {
            const parsedRecipients = parseRecipients();

            if (parsedRecipients.length === 1) {
              const parsedTemplate = Mustache.render(
                body || getBody(templateData.HtmlBody),
                parsedRecipients[0]
              );

              // await sendMailTemplate(recipients[0], selectedTemplate, selectedSender);
              result = await sendMail(
                parsedRecipients[0],
                templateData.Subject,
                parsedTemplate,
                selectedSender
              );
            } else {
              const mails = parsedRecipients.map((to) => {
                return {
                  email: to.email,
                  subject: templateData.Subject,
                  body: Mustache.render(body || getBody(templateData.HtmlBody), to),
                };
              });
              // await sendMailTemplateBatch(recipients, selectedTemplate, selectedSender);
              result = await sendMailBatchArray(mails, selectedSender);
            }
          } else if (subject && body) {
            const tmpBody = `<style>table { border: 1px solid black; }</style>${body}`;
            if (recipients.length === 1)
              result = await sendMail(recipients[0], subject, body, selectedSender);
            else result = await sendMailBatch(recipients, subject, body, selectedSender);
          }

          if (clearSelected) clearSelected();

          resetAll();
          setOpenModal(false);
          let type = "success";
          const success = [];
          const errors = [];

          if (result) {
            if (recipients.length === 1) {
              if (result.ErrorCode === 0) success.push(result.To);
              else errors.push(result.Message);
            } else {
              for (const res of result) {
                if (res.ErrorCode === 0) success.push(res.To);
                else errors.push(res.Message);
              }
            }
          }

          let message = `<p>To: ${success.map((e) => `<b>${e}</b>`).join(", ")}</p>`;

          if (errors.length > 0) {
            type = "warning";
            message +=
              '<br /><hr /><br /><h3 class="swal2-title" style="display: block; color: palevioletred;">Failed to send email to one or more recipients due to:</h3><br><ul style="width: 100%; height: 100px; overflow: auto">';
            message += errors.map((e) => `<li>${e}</li>`).join("");
            message += "</ul>";
          }

          // Swal.fire("Mail(s) sent", `To: ${recipients.map((r) => r.name).join(", ")}`, type);
          Swal.fire("Mail(s) sent", message, type);
        } else {
          // resetAll();
          setOpenModal(true);
        }
      });
    }
  };

  const handleClose = () => {
    setOpenModal(false);

    Swal.fire({
      icon: "warning",
      text: "Are you sure you want to close the Send Email screen?",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setMailType(null);
        resetAll();
        setOpenModal(false);
      } else {
        setOpenModal(true);
      }
    });
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      // sx={{ width: "50vw" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          // minHeight: 500,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: "15px",
        }}
      >
        <MDBox mb={1}>
          <MDTypography id="modal-title" variant="h6" component="h2" fontWeight="medium">
            Send Mail
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12} style={{ display: "flex" }}>
              <ToggleButtonGroup
                size="small"
                exclusive
                color={sidenavColor}
                value={mailType}
                name="mail-type-group"
                defaultValue="template"
                onChange={handleMailTypeChange}
                style={{ margin: "auto" }}
              >
                <ToggleButton value="template">Template</ToggleButton>
                <ToggleButton value="freetext">Free text</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {mailType == null && (
                <MDTypography
                  id="modal-description"
                  variant="h6"
                  component="h2"
                  fontWeight="light"
                  style={{ textAlign: "center" }}
                >
                  Please select email type by clicking on <strong>Template</strong> or{" "}
                  <strong>Free Text</strong>
                </MDTypography>
              )}
              {mailType === "template" && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl
                      sx={{ minWidth: 250, width: "94%", marginTop: 2, marginBottom: 2 }}
                    >
                      <InputLabel id="template-select-label">Select template</InputLabel>
                      <Select
                        labelId="template-select-label"
                        id="template-select"
                        label="Select template"
                        onChange={handleSelectedTemplateChange}
                        autoWidth
                        value={selectedTemplate}
                        sx={{ minHeight: 45 }}
                      >
                        <MenuItem key="-" value={null}>
                          None
                        </MenuItem>
                        <MenuItem key="32770108" value="32770108">
                          Application acknowledgement
                        </MenuItem>
                        <MenuItem key="32770590" value="32770590">
                          Program acceptance
                        </MenuItem>
                        <MenuItem key="32770592" value="32770592">
                          Program rejection
                        </MenuItem>
                        <MenuItem key="32770114" value="32770114">
                          Program kick off
                        </MenuItem>
                        <MenuItem key="32770115" value="32770115">
                          Program completion/ certification
                        </MenuItem>
                        <MenuItem key="32136215" value="32136215">
                          Please join
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl sx={{ m: 1, width: "93%", minHeight: 400 }}>
                      <RTEditor data={body} onChange={handleBodyChange} />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {mailType === "freetext" && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl sx={{ m: 1, width: "93%" }}>
                      <MDInput label="Subject" value={subject} onChange={handleSubjectChange} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl sx={{ m: 1, width: "93%", minHeight: 400 }}>
                      <RTEditor data={body} onChange={handleBodyChange} />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <MDButton
              component="button"
              onClick={handleClose}
              variant="gradient"
              color="secondary"
              fullWidth
            >
              Cancel
            </MDButton>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDButton
              component="button"
              onClick={handleSend}
              variant="gradient"
              color={sidenavColor}
              fullWidth
              // disabled={sendDisabled}
            >
              Send
            </MDButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

MailModal.propTypes = {
  recipients: PropTypes.array,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  clearSelected: PropTypes.func,
  selectedSender: PropTypes.object,
  meetId: PropTypes.string,
};

export { MailModal };
