import { Navigate } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ user, children }) => {
  if (!user) return <Navigate to="/authentication/sign-in" replace />;

  return children;
};

export default ProtectedRoute;
