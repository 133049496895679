import { useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Editor from "components/ckeditor5/ckeditor";
// import UDEditor from "./UDEditor";

const RTEditor = ({ data, onChange }) => {
  const handleChange = (event, editor) => {
    const newData = editor.getData();
    onChange(newData, event, editor);
  };
  const editorConfig = {
    toolbar: [
      "sourceEditing",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "alignment",
      "|",
      "outdent",
      "indent",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "link",
      "|",
      "insertTable",
      "blockQuote",
      "|",
      "undo",
      "redo",
    ],
    alignment: {
      options: ["left", "center", "right", "justify"],
    },
    link: {
      // addTargetToExternalLinks: true,
      decorators: {
        openInNewTab: {
          mode: "manual",
          label: "Open in a new tab",
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true,
        },
      ],
    },
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      // disabled={disabled}
      data={data}
      onChange={handleChange}
      config={editorConfig}
    />
  );
};

RTEditor.propTypes = {
  data: PropTypes.string,
  onChange: PropTypes.func,
};

export { RTEditor };
