async function sendMail(to, subject, body, sender) {
  const data = {
    From: sender.from,
    To: to.email,
    Subject: subject,
    TextBody: body,
    HtmlBody: body,
  };
  const res = await fetch(`/mail`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Postmark-Server-Token": process.env.REACT_APP_POSTMARK_TOKEN,
    },
    body: JSON.stringify(data),
  });

  return await res.json();
}

async function sendMailTemplate(to, template, sender) {
  const data = {
    From: sender.from,
    To: to.email,
    TemplateId: template,
    TemplateModel: {
      name: to.name,
      meetLink: to?.meetLink || "",
    },
  };
  const res = await fetch(`/mail/withTemplate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Postmark-Server-Token": process.env.REACT_APP_POSTMARK_TOKEN,
    },
    body: JSON.stringify(data),
  });

  return await res.json();
}

async function sendMailBatch(tos, subject, body, sender) {
  const data = tos.map((to) => {
    return {
      From: sender.from,
      To: to.email,
      Subject: subject,
      TextBody: body,
      HtmlBody: body,
    };
  });
  const res = await fetch(`/mail/batch`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Postmark-Server-Token": process.env.REACT_APP_POSTMARK_TOKEN,
    },
    body: JSON.stringify(data),
  });

  return await res.json();
}

async function sendMailBatchArray(mails, sender) {
  const data = mails.map((mail) => {
    return {
      From: sender.from,
      To: mail.email,
      Subject: mail.subject,
      TextBody: mail.body,
      HtmlBody: mail.body,
    };
  });
  const res = await fetch(`/mail/batch`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Postmark-Server-Token": process.env.REACT_APP_POSTMARK_TOKEN,
    },
    body: JSON.stringify(data),
  });

  return await res.json();
}

async function sendMailTemplateBatch(tos, template, sender) {
  const data = {
    Messages: tos.map((to) => {
      return {
        From: sender.from,
        To: to.email,
        TemplateId: template,
        TemplateModel: {
          name: to.name,
          meetLink: to?.meetLink || "",
        },
      };
    }),
  };
  const res = await fetch(`/mail/batchWithTemplates`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Postmark-Server-Token": process.env.REACT_APP_POSTMARK_TOKEN,
    },
    body: JSON.stringify(data),
  });

  return await res.json();
}

export { sendMail, sendMailBatch, sendMailTemplate, sendMailTemplateBatch, sendMailBatchArray };
