/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import GoogleIcon from "@mui/icons-material/Google";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { useMaterialUIController, setUser } from "context";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import MDButton from "components/MDButton";
import udLogo from "assets/images/uu-logo-multi.png";
import { useState } from "react";
import Swal from "sweetalert2";

function Basic() {
  const [ssoPrompt, setSsoPrompt] = useState("");
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  const handleLogin = async () => {
    window.tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        // Swal.fire("", `${resp.error}`, "error");
        Swal.fire(
          "",
          "You must give the appropriate permission to launch this application",
          "error"
        );

        return false;
      }

      const scopes = resp.scope.split(" ");

      if (scopes.length !== 11) {
        Swal.fire(
          "",
          "You must give the appropriate permission to launch this application",
          "error"
        );
        setSsoPrompt("consent");

        return false;
      }

      const res = await fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${resp.access_token}`
      );
      const user = await res.json();

      user.token = resp;
      setUser(dispatch, user);
      navigate("/dashboard");
    };
    window.tokenClient.requestAccessToken({ prompt: ssoPrompt });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <img src={udLogo} alt="UpSkill Digital" style={{ width: "80%" }} />
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            UpSkill Meet Tracker
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}> */}
          <div style={{ width: "50%", margin: "auto", marginTop: 10 }}>
            <MDButton onClick={handleLogin}>
              <GoogleIcon color="inherit" />
              <MDTypography
                variant="span"
                fontWeight="medium"
                color="dark"
                sx={{ marginTop: "2px", marginLeft: "3px" }}
              >
                Sign in with Google
              </MDTypography>
            </MDButton>
          </div>
          {/* <MDTypography component={MuiLink} onClick={handleLogin} variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography> */}
          {/* </Grid>
          </Grid> */}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
