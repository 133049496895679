import { useState, forwardRef, useImperativeHandle } from "react";
import { PropTypes } from "prop-types";
import {
  Box,
  Grid,
  Modal,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import Swal from "sweetalert2";
import { useMaterialUIController } from "context";
import { usePicker } from "hooks/usePickerAPI";
import { useReadRange, useGetSpreadsheet } from "hooks/useSheetAPI";
import placeholder from "assets/images/placeholder.png";

const EnrolledModal = forwardRef(
  (
    {
      setSelectedSheet,
      setSelectedSheetSheet,
      setSheetRows,
      setEnrolledData,
      openModal,
      setOpenModal,
    },
    ref
  ) => {
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
    const [existingSheet, setExistingSheet] = useState(null);
    const [spreadsheetSheets, setSpreadsheetSheets] = useState(null);
    const [selectedSpreadsheetSheet, setSelectedSpreadsheetSheet] = useState(null);
    const [sheetData, setSheetData] = useState([]);
    const [reading, setReading] = useState(false);
    const [selectDisabled, setSelectDisabled] = useState(true);

    const showPicker = usePicker();
    const readRange = useReadRange();
    const getSpreadsheet = useGetSpreadsheet();

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const resetAll = () => {
      setExistingSheet(null);
      setSpreadsheetSheets(null);
      setSelectedSpreadsheetSheet(null);
      setSheetData([]);
      setReading(false);
      setSelectDisabled(true);
    };

    const getSpreadsheetSheets = async (doc) => {
      const sheet = doc;

      if (sheet && sheet.id) {
        const ss = await getSpreadsheet(sheet.id);
        const sheets = ss.result.sheets
          .map((sh) => {
            return {
              id: sh.properties.sheetId,
              index: sh.properties.index,
              type: sh.properties.sheetType,
              title: sh.properties.title,
            };
          })
          .filter((sh) => sh.type === "GRID");

        if (sheets.length === 1) {
          const sh = sheets[0];

          setSpreadsheetSheets(
            <MenuItem key={sh.id} value={JSON.stringify(sh)}>
              {sh.title}
            </MenuItem>
          );
          setSelectedSpreadsheetSheet(JSON.stringify(sh));
          setReading(true);

          const data = await readSheetRange(doc, sh);

          setReading(false);
          setSelectDisabled(false);
          setSelectedSheet(doc);
          setSelectedSheetSheet(JSON.stringify(sh));
          setEnrolledData(data);
          setSheetRows(data);
          handleCloseModal();
        } else {
          const renderSheets = sheets.map((sh) => (
            <MenuItem key={sh.id} value={JSON.stringify(sh)}>
              {sh.title}
            </MenuItem>
          ));
          setSpreadsheetSheets(renderSheets);
        }
      }
    };

    const readSheetRange = async (spreadSheet, sheet) => {
      const resp = await readRange(spreadSheet.id, `${sheet.title}!A2:E`);
      const data = resp?.result?.values.map((row) => {
        return {
          name: `${row[1]?.trim()} ${row[2]?.trim()}`.trim(),
          email: row[0]?.trim(),
          company: row[3]?.trim(),
          phone: row[4]?.trim(),
          picture: placeholder,
        };
      });
      // .filter((row) => {
      //   return mailFormat.test(row.email);
      // });

      if (data && data.length > 0) {
        setSheetData(data);

        return data;
      } else {
        setSheetData([]);
        setOpenModal(false);
        Swal.fire({
          icon: "warning",
          text: "Please make sure that the spreadsheet is well formatted",
        }).then(() => {
          setOpenModal(true);
        });

        return [];
      }
    };

    const handleOpenExistingSheet = (e) => {
      showPicker(async (data) => {
        if (data && data.action === "picked") {
          const doc = data.docs[0];

          setSelectDisabled(true);
          setSpreadsheetSheets(null);
          setSheetData([]);
          setReading(true);
          setExistingSheet(doc);
          await getSpreadsheetSheets(doc);
          setReading(false);
        }
      });
    };

    const handleSpreadsheetSheetChange = async (e) => {
      const sheet = JSON.parse(e.target.value);

      setSelectedSpreadsheetSheet(JSON.stringify(sheet));
      setReading(true);
      await readSheetRange(existingSheet, sheet);
      setReading(false);
      setSelectDisabled(false);
    };

    const handleSelectSpreadsheet = async (e) => {
      if (!sheetData || sheetData.length === 0) {
        setOpenModal(false);
        Swal.fire({
          icon: "warning",
          text: "Please select a spreadsheet and a valid sheet",
        }).then(() => {
          setOpenModal(true);
        });

        return;
      }

      setSelectedSheet(existingSheet);
      setSelectedSheetSheet(selectedSpreadsheetSheet);
      setEnrolledData(sheetData);
      setSheetRows(sheetData);
      handleCloseModal();
    };

    const handleCloseModal = () => {
      // resetAll();
      setOpenModal(false);
    };

    useImperativeHandle(ref, () => ({
      async reloadSheet(spreadSheet, sheet) {
        const sheetObj = typeof sheet === "string" ? JSON.parse(sheet) : sheet;

        return await readSheetRange(spreadSheet, sheetObj);
      },
    }));

    return (
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-enrolled-title"
        aria-describedby="modal-enrolled-escription"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "15px",
          }}
        >
          <MDBox mb={1}>
            <MDTypography id="modal-export-title" variant="h6" component="h2" fontWeight="medium">
              Select enrolled list
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={5} lg={5}>
                      <MDButton
                        component="button"
                        variant="outlined"
                        color="secondary"
                        onClick={handleOpenExistingSheet}
                      >
                        <FileOpenOutlinedIcon style={{ width: 30, height: 30 }} />
                        &nbsp;Select a file
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} md={7} lg={7}>
                      <MDTypography
                        display="block"
                        variant="button"
                        fontWeight="medium"
                        style={{ marginTop: 10 }}
                      >
                        {existingSheet?.name || ""}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl sx={{ m: 1, width: "93%", margin: "8px 0 0 0" }}>
                        <InputLabel id="select-sheet-label">Select sheet</InputLabel>
                        <Select
                          labelId="select-sheet-label"
                          id="select-sheet"
                          label="Select sheet"
                          onChange={handleSpreadsheetSheetChange}
                          autoWidth
                          value={selectedSpreadsheetSheet}
                          sx={{ minHeight: 45 }}
                        >
                          {spreadsheetSheets}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <MDButton
                component="button"
                onClick={handleCloseModal}
                variant="gradient"
                color="secondary"
                fullWidth
              >
                Cancel
              </MDButton>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDButton
                component="button"
                onClick={handleSelectSpreadsheet}
                variant="gradient"
                color={sidenavColor}
                disabled={selectDisabled}
                fullWidth
              >
                Ok
              </MDButton>
            </Grid>
          </Grid>
          {reading && (
            <CircularProgress
              size={24}
              sx={{
                color: "green",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </Modal>
    );
  }
);

EnrolledModal.propTypes = {
  setSelectedSheet: PropTypes.func,
  setSelectedSheetSheet: PropTypes.func,
  setSheetRows: PropTypes.func,
  setEnrolledData: PropTypes.func,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};

export { EnrolledModal };
