/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import {
  FormControl,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  ClickAwayListener,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Badge,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { UDList } from "components/List";
import { useMaterialUIController } from "context";
import { usePicker } from "hooks/usePickerAPI";
import { useReadRange } from "hooks/useSheetAPI";
// import useWebSocket, { ReadyState } from "react-use-websocket";
import MDButton from "components/MDButton";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import { LinkModal } from "components/Modals/Link";
import { ExportModal } from "components/Modals/Export";
import { MeetModal } from "components/Modals/Meet";
import { MailModal } from "components/Modals/Mail";
import MDInput from "components/MDInput";
import moment, { max } from "moment";
import Swal from "sweetalert2";
import { loginCouchDb, getDocumentById, getLinks, saveLinks } from "lib/couchDb";
import { CalendarModal } from "components/Modals/Calendar";
import { EnrolledModal } from "components/Modals/Enrolled";
import { compareNames, compareNamesScore } from "lib/matchStrings";

function Dashboard() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const showPicker = usePicker();
  const readRange = useReadRange();
  const [selectedSheet, setSelectedSheet] = useState({});
  const [selectedSheetSheet, setSelectedSheetSheet] = useState({});
  const [selectedMeet, setSelectedMeet] = useState(null);
  const [enrolledData, setEnrolledData] = useState([]);
  const [attendeeData, setAttendeeData] = useState([]);
  const [reconciledData, setReconciledData] = useState([]);
  const [absenteeData, setAbsenteeData] = useState([]);
  const [sheetRows, setSheetRows] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [currentSessionTime, setCurrentSessionTime] = useState(null);
  const [meetId, setMeetId] = useState("");
  const [revisionId, setRevisionId] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openMeetModal, setOpenMeetModal] = useState(false);
  const [openMailModal, setOpenMailModal] = useState(false);
  const [openEnrolledModal, setOpenEnrolledModal] = useState(false);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [enrolledTooltipOpen, setEnrolledTooltipOpen] = useState(false);
  const [reconciledTooltipOpen, setReconciledTooltipOpen] = useState(false);
  const [linkItem, setLinkItem] = useState(null);
  const [allSelectedMails, setAllSelectedMails] = useState([]);
  const [senderMails, setSenderMails] = useState([]);
  const [selectedSender, setSelectedSender] = useState(null);
  const [sessionStartTime, setSessionStartTime] = useState(null);
  const [sessionEndTime, setSessionEndTime] = useState(null);
  const [exportAttendees, setExportAttendees] = useState(false);
  const [maxTimeSlice, setMaxTimeSlice] = useState(0);
  const [attendeeSliced, setAttendeeSliced] = useState(false);
  const [refreshMeetEnabled, setRefreshMeetEnabled] = useState(false);
  const [linkedItems, setLinkedItems] = useState(null);
  const reconListCmp = useRef();
  const enrolledModalCmp = useRef();

  const formatDiffTime = (diff) => {
    const totalMinutes = parseInt(Math.floor(diff / 60), 0);
    let seconds = parseInt(diff % 60, 0);
    let minutes = parseInt(Math.floor(totalMinutes % 60), 0);
    let hours = parseInt(Math.floor(totalMinutes / 60), 0);

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${hours}:${minutes}`; //:${seconds}`;
  };

  const formatDate = (date) => {
    if (date) return moment(date).format("DD MMM YYYY HH:mm (ZZ)");

    return "";
  };

  const getBlockSeconds = (blockStart, blockSeconds, filterStart, filterEnd) => {
    const fixedSeconds = blockSeconds === 89 ? 90 : blockSeconds == 59 ? 60 : blockSeconds;
    const blockStartTime = moment(blockStart).startOf("second");
    // const blockEndTime = moment(blockStart).add(fixedSeconds, "seconds").startOf("second");
    const endTime = filterEnd.startOf("minute").diff(blockStartTime, "seconds");
    const blockStartMinute = moment(blockStart).startOf("minute");
    const blockEndMinute = moment(blockStart).add(fixedSeconds, "seconds").startOf("minute");
    const blockStartDiff = blockStartTime.diff(blockStartMinute, "seconds");
    const filterStartDiff = blockStartMinute.diff(filterStart.startOf("minute"), "seconds");
    const filterStartEndDiff = blockEndMinute.diff(filterStart.startOf("minute"), "seconds");
    const filterEndDiff = filterEnd.startOf("minute").diff(blockStartMinute, "seconds");
    const lowerLimit = filterStartDiff >= 0 || filterStartEndDiff >= 0;
    const upperLimit = filterEndDiff >= 60;
    let result = 0;

    if (lowerLimit && upperLimit) {
      if (filterStartEndDiff == 60) {
        result = filterStartEndDiff;
      } else if (filterStartDiff === 0) {
        result = fixedSeconds + blockStartDiff;
      } else if (filterEndDiff === 60) {
        result = endTime;
      } else {
        result = fixedSeconds;
      }
    }

    return result;
  };

  const parseBlocks = (attendee, startTime, endTime) => {
    const result = { ...attendee };
    const sessStartTime = startTime || sessionStartTime;
    const sessEndTime = endTime || sessionEndTime;

    if (sessStartTime && sessEndTime && attendee.blocks && attendee.blocks?.length > 0) {
      const calculatedBlocks = attendee.blocks
        .map((block) =>
          getBlockSeconds(block.startTime, block.totalSeconds, sessStartTime, sessEndTime)
        )
        .filter((b) => b > 0);

      if (calculatedBlocks[1] > 90) calculatedBlocks.shift();

      result.blocksSeconds = calculatedBlocks.reduce((acc, curr) => acc + curr, 0);
      // console.log(result.name, result.blocksSeconds, calculatedBlocks);
    }

    return result;
  };

  const getScores = (attendees, row) => {
    const name = row.name.toLowerCase();
    const altName = row.as?.name.toLowerCase();
    const company = row.company?.toLowerCase();
    const email = row.email?.toLowerCase();
    const phone = row.phone?.toLowerCase();
    const result = attendees.reduce((filtered, att) => {
      const nameScores = compareNamesScore(att.name, name);
      const altScores = compareNamesScore(att.name, altName);
      const compScores = compareNamesScore(att.name, company);

      if (nameScores.score >= nameScores.threshold) filtered.push({ ...nameScores, att, row });
      else if (altScores.score >= altScores.threshold) filtered.push({ ...altScores, att, row });
      else if (compScores.score >= compScores.threshold) filtered.push({ ...compScores, att, row });
      else if (email !== undefined) {
        const cleanEmail = email.split("@")[0].replace(/[\d \-+\.]/gi, "");
        const cleanName = att.name.replace(/[\d \-+\.]/gi, "");
        const emailScores = compareNamesScore(cleanName, cleanEmail);

        if (emailScores.score >= emailScores.threshold) filtered.push({ ...emailScores, att, row });
      } else if (phone !== undefined) {
        const cleanName = att.name.replace(/[^\d]/gi, "").replace(/^00/i, "");
        const cleanPhone = phone ? phone.replace(/[^\d]/gi, "").replace(/^00/i, "") : "";

        if (cleanName == cleanPhone) {
          filtered.push({
            score: 1,
            threshold: 0.8,
            origName: phone,
            name: att.name,
            att,
            row,
          });
        }
      }

      return filtered;
    }, []);

    return result; //.reduce((acc, curr) => (curr.length > 0 ? acc.concat(curr) : acc), []);
  };

  const findMatch = (attendees, row) => {
    const name = row.name.toLowerCase();
    const altName = row.as?.name.toLowerCase();
    const company = row.company?.toLowerCase();
    const phone = row.phone?.toLowerCase();
    const matched = [];

    return attendees.find((att) => {
      if (matched.includes(att.name)) return false;

      const eqName = compareNames(att.name, name);
      const eqAlt = compareNames(att.name, altName);
      const eqComp = compareNames(att.name, company);
      // const cleanName = att.name.replace(/[^\d]/gi, "").replace(/^00/i, "");
      // const cleanPhone = phone ? phone.replace(/[^\d]/gi, "").replace(/^00/i, "") : "";
      const eqPhone = compareNames(att.name, phone); //cleanName === cleanPhone;
      const match = eqName || eqAlt || eqComp || eqPhone;

      if (match) matched.push(att.name);

      return match;
    });
  };

  const parseLinks = (links, enrolled) => {
    if (!links) return enrolled;

    const parsed = enrolled.map((e) => {
      const email = e.email;
      const link = links[email] || null;

      delete e?.as;
      delete e?.is;

      if (link) {
        const rel = { ...link.unknown };

        delete rel?.as;
        delete rel?.is;
        e.as = rel;
        e.prelinked = true;
      }

      return e;
    });

    // setSheetRows(parsed);
    // setEnrolledData(parsed);

    return parsed;
  };

  const getPresent = (links = null) => {
    const present = [];
    const matched = [];

    if (sheetRows && attendees) {
      let parsedData = sheetRows;

      if (links || linkedItems) {
        // const week = moment(selectedMeet.startTime).isoWeek();
        // const linksId = `${meetId.split("-").slice(1).join("-")}-${week}`;
        // const links = await getLinks(linksId);
        parsedData = parseLinks(links || linkedItems, sheetRows);

        // setLinkedItems(links);
      }

      for (const row of parsedData) {
        const altName = row.as?.name.toLowerCase();
        // const phone = row.phone?.toLowerCase();
        // const attendee = findMatch(attendees, row);
        const scores = getScores(attendees, row);

        if (scores.length > 0) {
          const best = scores.reduce((prev, curr) =>
            prev && prev.score > curr.score ? prev : curr
          );
          const attendee = best.att;

          if (matched.includes(attendee.name)) continue;

          attendee.email = row.email;
          attendee.company = row.company;
          attendee.phone = row.phone;

          const byAltName = altName ? compareNames(attendee.name.toLowerCase(), altName) : false;
          // const cleanName = attendee.name.replace(/[^\d]/gi, "").replace(/^00/i, "");
          // const cleanPhone = phone ? phone.replace(/[^\d]/gi, "").replace(/^00/i, "") : "";
          // const byPhone = cleanName == cleanPhone;

          if (row.as && byAltName) {
            const rel = { ...row };

            delete rel?.as;
            delete rel?.is;
            attendee.is = rel;
          }
          // else if (byPhone) attendee.is = { ...row };

          matched.push(attendee.name);
          present.push(attendee);
        }
      }
    }

    return present;
  };

  const getMissing = (links = null) => {
    const missing = [];
    // const matched = [];

    if (sheetRows && attendees) {
      const present = getPresent(links);

      for (const attendee of attendees) {
        const exists = present.findIndex((p) => p.name === attendee.name);

        if (exists === -1) missing.push(attendee);
      }
    }

    return missing;
  };

  const getAbsent = () => {
    const absent = [];

    setAbsenteeData([]);

    if (sheetRows && attendees) {
      for (const row of sheetRows) {
        // const attendee = findMatch(attendees, row);
        const scores = getScores(attendees, row);

        if (scores.length === 0) absent.push(row);
      }

      setAbsenteeData(absent);
    }

    return absent;
  };

  const reconcileLists = (value, empty = false, links = null) => {
    let reconciled = [];
    let filter = value || filterValue;

    if (empty) setReconciledData([]);

    if (sheetRows && attendees) {
      if (filter === "enrolled") {
        // sheetRows?.forEach((row) => {
        //   reconciled.push(row);
        // });
        reconciled = Array.from(sheetRows);
      } else if (filter === "present") {
        reconciled = getPresent(links);
      } else if (filter === "unknown") {
        reconciled = getMissing(links);
      } else if (filter === "absent") {
        reconciled = getAbsent();
      }
    }

    if (reconciled.length > 0) {
      setReconciledData(reconciled);
    } else {
      setReconciledData([]);
    }
  };

  const updateAttendeeList = async (currentSession, startTime = null, endTime = null) => {
    if (currentSession) {
      const parsedAttendees = currentSession.attendees
        .map((att) => parseBlocks(att, startTime, endTime))
        .filter((att) => att.blocksSeconds > 0);

      if (startTime) {
        if (endTime) setMaxTimeSlice(endTime.diff(startTime, "minutes") * 60);
        else {
          const totalMinutes = parseInt(Math.floor(currentSession.totalSeconds / 60), 0);
          // const minutes = parseInt(Math.floor(totalMinutes % 60), 0);
          const end = moment(startTime).add(totalMinutes, "minutes");

          setMaxTimeSlice(end.diff(startTime, "minutes") * 60);
        }
      }

      setAttendeeData(parsedAttendees); //currentSession.attendees); //tableData);
      setCurrentSessionTime(formatDiffTime(currentSession.totalSeconds));
      setAttendees(parsedAttendees); //currentSession.attendees);
    } else {
      setAttendeeData([]); //{ columns: [], rows: [] });
      setCurrentSessionTime("");
      setAttendees([]);
    }
  };

  const getSendersList = async (e) => {
    const loggedIn = await loginCouchDb();
    const doc = await getDocumentById("mail%2Fsenders");

    if (doc) {
      setSenderMails(doc.list);
    }
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;

    setFilterValue(value);
    reconListCmp.current.unselectAll();
    reconcileLists(value, true);
  };

  const handleRefresh = (e) => {
    reconListCmp.current.unselectAll();
    reconcileLists();
  };

  // const handleMailAction = (item) => {
  //   if (!selectedSender) {
  //     Swal.fire("", "You must select a 'sender email' before you can send emails", "warning");

  //     return;
  //   }

  //   if (item && item.email) {
  //     reconListCmp.current.unselectAll();
  //     setAllSelectedMails([item]);
  //     setOpenMailModal(true);
  //   }
  // };

  const handleMailAll = async (e) => {
    if (!selectedSender) {
      Swal.fire("", "You must select a 'sender email' before you can send emails", "warning");

      return;
    }

    if (allSelectedMails && allSelectedMails.length > 0) {
      setOpenMailModal(true);
    }
  };

  const handleExport = (e) => {
    // setFilterValue("present");
    setExportAttendees(false);
    reconcileLists(filterValue || "present");
    setOpenExportModal(true);
    reconListCmp.current.unselectAll();
  };

  const handleExportAttendee = (e) => {
    setExportAttendees(true);
    setOpenExportModal(true);
  };

  const handleOpenSource = (e) => {
    setOpenEnrolledModal(true);
    // showPicker((data) => {
    //   if (data && data.action === "picked") {
    //     const doc = data.docs[0];
    //     console.log(doc);
    //     handleSheetChange(doc);
    //   }
    // });
  };

  // const handleOpenEvent = (e) => {
  //   setOpenCalendarModal(true);
  // };

  const handleRefreshSource = async (e) => {
    if (!selectedSheet?.id || typeof selectedSheetSheet !== "string") return;

    const data = await enrolledModalCmp.current.reloadSheet(selectedSheet, selectedSheetSheet);
    let parsedData = data;

    if (linkedItems) {
      // const week = moment(selectedMeet.startTime).isoWeek();
      // const linksId = `${meetId.split("-").slice(1).join("-")}-${week}`;
      // const links = await getLinks(linksId);
      parsedData = parseLinks(linkedItems, data);

      // setLinkedItems(links);
    }

    setEnrolledData(parsedData);
    setSheetRows(parsedData);
  };

  const handleOpenMeet = (e) => {
    setOpenMeetModal(true);
  };

  const handleRefreshMeet = (e) => {
    // const initTime = moment(selectedMeet.startTime);
    // let finishTime = null;

    // if (selectedMeet.endTime) {
    //   finishTime = moment(selectedMeet.endTime);
    // } else {
    //   finishTime = moment(selectedMeet.startTime).add(selectedMeet.totalSeconds, "seconds");
    // }

    handleSelectedMeet(selectedMeet, sessionStartTime, sessionEndTime, attendeeSliced); // initTime, finishTime);
  };

  const refreshDocument = async (id, startTime = null, endTime = null, sliced = false) => {
    console.log("refreshing", new Date());
    if (id) {
      const doc = await getDocumentById(id);

      if (doc && doc._id) {
        setMeetId(doc._id);
        setSelectedMeet(doc);

        if (sliced) await updateAttendeeList(doc, startTime, endTime);
        else {
          const startT = moment(doc.startTime);
          let endT = null;

          if (doc.endTime) {
            endT = moment(doc.endTime);
          } else {
            endT = moment(doc.startTime).add(doc.totalSeconds, "seconds");
          }

          setSessionStartTime(startT);
          setSessionEndTime(endT);
          await updateAttendeeList(doc, startT, endT);
        }
      }
    }
  };

  const handleSelectedMeet = async (meet, startTime, endTime, sliced) => {
    if (window.refreshInterval) {
      clearInterval(window.refreshInterval);
      window.refreshInterval = null;
    }

    setSelectedMeet(meet);
    setSessionStartTime(startTime);
    setSessionEndTime(endTime);
    setAttendeeSliced(sliced);
    setRefreshMeetEnabled(true);

    if (meet?._id) {
      setMeetId(meet._id);

      // await updateAttendeeList(meet, startTime, endTime);
      await refreshDocument(meet._id, startTime, endTime, sliced);

      const now = moment();
      let meetEnd = null;

      if (meet.endTime) {
        meetEnd = moment(meet.endTime);
      } else {
        meetEnd = moment(meet.startTime).add(meet.totalSeconds, "seconds");
      }

      const autoRefresh = now.diff(meetEnd, "minutes") <= 15;

      if (autoRefresh) {
        window.refreshInterval = setInterval(
          async () => await refreshDocument(meet._id, startTime, endTime, sliced),
          60 * 1000
        );
      }
    } else {
      setMeetId({});
      setLinkedItems(null);
      await updateAttendeeList(null);
    }
  };

  const handleLinkAction = async () => {
    if (meetId) {
      // const week = moment(selectedMeet.startTime).isoWeek();
      // const id = `${meetId.split("-").slice(1).join("-")}-${week}`;
      // const links = await getLinks(id);

      getAbsent();
      // setLinkedItems(links);
      setOpenLinkModal(true);
    } else {
      Swal.fire({
        icon: "info",
        title: "Please select a meet to proceed",
      });
    }
  };

  const handleUnlinkAction = async (item) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `Are you sure you want to unlink ${item.name} who is currently linked to ${item.is.name}?`,
      showCancelButton: true,
    }).then(async (resp) => {
      if (resp.isConfirmed) {
        if (linkedItems) {
          const week = moment(selectedMeet.startTime).isoWeek();
          const id = `${meetId.split("-").slice(1).join("-")}-${week}`;
          const links = await getLinks(id);
          const email = item.is.email;

          if (Object.keys(links).includes(email)) delete links[email];

          setLinkedItems(links);
          await saveLinks(id, links);

          const parsedData = parseLinks(links, [...enrolledData]);

          setEnrolledData(parsedData);
          setSheetRows(parsedData);
          setFilterValue("present");
          reconcileLists("present", true, links);
          handleRefreshMeet();
        }
      }
    });
  };

  const handleLink = async (links) => {
    const week = moment(selectedMeet.startTime).isoWeek();
    const id = `${meetId.split("-").slice(1).join("-")}-${week}`;

    setLinkedItems(links);
    await saveLinks(id, links);

    const parsedData = parseLinks(links, [...enrolledData]);

    setSheetRows(parsedData);
    setEnrolledData(parsedData);
    setTimeout(() => {
      setFilterValue("unknown");
      reconcileLists("unknown", true, links);
    }, 100);
  };

  // const handleReset = () => {
  //   handleSheetChange({});
  //   handleSelectedMeet(null);
  //   handleRefresh();
  // };

  const tooltipPopperProps = {
    sx: {
      "& .MuiTooltip-arrow": {
        color: "#FBFBFB !important",
      },
      "& .MuiTooltip-tooltip": {
        maxWidth: "650px",
        background: "#FBFBFB",
        color: "black !important",
        "& > p": {
          textAlign: "left",
        },
        "& > ul": {
          listStyleType: "circle",
          listStylePosition: "inside",
          "& > li": {
            textAlign: "left",
            padding: "5px",
          },
        },
      },
    },
  };

  const enrolledTooltipText = (
    <>
      <p>
        The Enrolled list comprises individuals who have been invited to the Google Meet session.
        This data needs to be uploaded to a Google Sheet with the specific columns in a designated
        sequence:
      </p>
      <ul>
        <li>
          Row 1: Reserved for labels - please refrain from utilising this initial row for enrolled.
          data.
        </li>
        <li>Column 1 (mandatory): Email.</li>
        <li>
          Column 2 (mandatory): Name - this can encompass either the First Name or the complete
          name.
        </li>
        <li>
          Column 3: Surname - In cases where Column 2 solely contains the first name, employ this
          column for the surname.
        </li>
        <li>Column 4: Business Name.</li>
        <li>Column 5: Phone number.</li>
      </ul>
    </>
  );

  const handleEnrolledTooltipOpen = () => {
    setEnrolledTooltipOpen(true);
  };

  const handleEnrolledTooltipClose = () => {
    setEnrolledTooltipOpen(false);
  };

  const reconciledTooltipText = (
    <>
      <p>
        The Reconciled list has multiple views, which result from the app matching the names on the
        Enrolled list with the names on the Meet Attendee list. The matching process considers both
        the individual&apos;s name and company name (if provided in the Enrolled list). We utilise{" "}
        <i>string-similarity-js</i> to perform the name string matching.
      </p>
      <p>Here are the different views:</p>
      <ul>
        <li>
          Enrolled: This view contains the same list as the Enrolled list. It is replicated here to
          facilitate sending emails to the enrolled cohort
        </li>
        <li>
          Present: This view comprises the list of individuals who have been successfully matched,
          meaning they are attending the Google Meet from the Enrolled list
        </li>
        <li>
          Absent: In this view, you&apos;ll find a list of absentees, which includes those from the
          Enrolled list whose names have not been matched with the Meet Attendee list
        </li>
        <li>
          Present but unmatched: This view contains a list of individuals who were present on the
          Google Meet, but their names were not found (matched) on the Enrolled list
        </li>
      </ul>
      <p>
        You can export the list of those present in the meet to a Google Sheet. If you choose &quot;
        <b>New Sheet</b>&quot;, the file will be saved in your Google Drive. Choose &quot;
        <b>Existing Sheet</b>&quot; if you want to save the data to an existing file. GHA Specific
        is specific to the requirements of the GHA programme.
      </p>
    </>
  );

  const handleReconciledTooltipOpen = () => {
    setReconciledTooltipOpen(true);
  };

  const handleReconciledTooltipClose = () => {
    setReconciledTooltipOpen(false);
  };

  const handleSelectedSenderChange = (evt) => {
    setSelectedSender(evt.target.value);
  };

  useEffect(() => {
    const loadLinks = async () => {
      const week = moment(selectedMeet.startTime).isoWeek();
      const linksId = `${selectedMeet._id.split("-").slice(1).join("-")}-${week}`;
      const links = await getLinks(linksId);

      setLinkedItems(links);
    };

    if (selectedMeet) loadLinks();

    getAbsent();
  }, [selectedSheet, selectedMeet]);

  useEffect(() => {
    handleRefresh();
  }, [sheetRows, attendeeData]);

  useEffect(() => {
    const loadSendersList = async () => {
      await getSendersList();
    };

    loadSendersList();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={1} lg={1} style={{ display: "flex", alignItems: "center" }}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              Enrolled list:
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <FormControl style={{ width: "100%" }}>
              <MDInput value={selectedSheet?.name} disabled size="medium" />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={1} lg={1} style={{ display: "flex", alignItems: "center" }}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              eMail Sender:
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="sender-select-label">Select a sender</InputLabel>
              <Select
                labelId="sender-select-label"
                id="sender-select"
                label="Select a sender"
                onChange={handleSelectedSenderChange}
                autoWidth
                value={selectedSender}
                sx={{ minHeight: 45 }}
              >
                <MenuItem key="-" value={null}>
                  None
                </MenuItem>
                {senderMails &&
                  senderMails.map((sender) => (
                    <MenuItem key={sender.from} value={sender}>
                      {sender.name} &lt;{sender.from}&gt;
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={1} lg={1} style={{ display: "flex", alignItems: "center" }}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              Meet Name:
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={3} lg={5}>
            <FormControl style={{ width: "100%" }}>
              <MDInput value={selectedMeet?.name} disabled size="medium" />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={1} lg={1} style={{ display: "flex", alignItems: "center" }}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              Meet Date:
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <FormControl style={{ width: "100%" }}>
              <MDInput value={formatDate(selectedMeet?.startTime)} disabled size="medium" />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={1} lg={1} style={{ display: "flex", alignItems: "center" }}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              Meet Duration:
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <FormControl style={{ width: "100%" }}>
              <MDInput
                value={currentSessionTime ? currentSessionTime : ""}
                disabled
                size="medium"
              />
            </FormControl>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} lg={3}>
              <MDBox mb={1} style={{ display: "flex", alignItems: "center" }}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  Enrolled list
                </MDTypography>
                <ClickAwayListener onClickAway={handleEnrolledTooltipClose}>
                  <Tooltip
                    onClose={handleEnrolledTooltipClose}
                    open={enrolledTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    PopperProps={tooltipPopperProps}
                    title={enrolledTooltipText}
                  >
                    <HelpOutlinedIcon
                      color={sidenavColor}
                      size="small"
                      onClick={handleEnrolledTooltipOpen}
                      style={{ marginLeft: 5, cursor: "hand" }}
                    />
                  </Tooltip>
                </ClickAwayListener>
              </MDBox>
              <MDBox mb={2}>
                <Tooltip title="Select file">
                  <MDButton onClick={handleOpenSource} style={{ marginTop: 5 }}>
                    <FileOpenOutlinedIcon size="small" />
                  </MDButton>
                </Tooltip>{" "}
                {/* <Tooltip title="Select a Calendat event">
                  <MDButton onClick={handleOpenEvent} style={{ marginTop: 5 }}>
                    <CalendarMonthIcon size="small" />
                  </MDButton>
                </Tooltip>{" "} */}
                <Tooltip title="Refresh enrolled list">
                  <MDButton onClick={handleRefreshSource} style={{ marginTop: 5 }}>
                    <RefreshIcon size="small" />
                  </MDButton>
                </Tooltip>
              </MDBox>
              <MDBox mb={3}>
                <UDList rows={enrolledData} withCheckbox={false} />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <MDBox mb={1} style={{ display: "flex", alignItems: "center" }}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {sessionStartTime &&
                    sessionEndTime &&
                    `Meet Attendee list, between ${sessionStartTime.format(
                      "HH:mm"
                    )} and ${sessionEndTime.format("HH:mm")}`}
                  {(!sessionStartTime || !sessionEndTime) && `Meet Attendee list`}
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <Tooltip title="Select Google Meet">
                  <MDButton onClick={handleOpenMeet} style={{ marginTop: 5 }}>
                    <FileOpenOutlinedIcon size="small" />
                  </MDButton>
                </Tooltip>{" "}
                <Tooltip title="Refresh attendee list">
                  <MDButton
                    onClick={handleRefreshMeet}
                    disabled={!refreshMeetEnabled}
                    style={{ marginTop: 5 }}
                  >
                    <RefreshIcon size="small" />
                  </MDButton>
                </Tooltip>{" "}
                <Tooltip title="Export list">
                  <MDButton
                    onClick={handleExportAttendee}
                    style={{ marginTop: 5 }}
                    disabled={!selectedMeet}
                    color={sidenavColor}
                  >
                    <SaveIcon size="small" />
                  </MDButton>
                </Tooltip>
              </MDBox>
              <MDBox mb={3}>
                <UDList
                  rows={attendeeData}
                  withCheckbox={false}
                  kind="time"
                  maxTimeSlice={maxTimeSlice}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={1} style={{ display: "flex", alignItems: "center" }}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  Reconciled list
                </MDTypography>
                <ClickAwayListener onClickAway={handleReconciledTooltipClose}>
                  <Tooltip
                    onClose={handleReconciledTooltipClose}
                    open={reconciledTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    PopperProps={tooltipPopperProps}
                    title={reconciledTooltipText}
                  >
                    <HelpOutlinedIcon
                      color={sidenavColor}
                      size="small"
                      onClick={handleReconciledTooltipOpen}
                      style={{ marginLeft: 5, cursor: "hand" }}
                    />
                  </Tooltip>
                </ClickAwayListener>
              </MDBox>
              <MDBox mb={1}>
                <FormControl sx={{ m: 1, minWidth: 200, marginLeft: 0 }}>
                  <ToggleButtonGroup
                    size="small"
                    exclusive
                    color={sidenavColor}
                    value={filterValue}
                    name="reconciled-group"
                    defaultValue="present"
                    onChange={handleFilterChange}
                  >
                    <ToggleButton value="enrolled">Enrolled</ToggleButton>
                    <ToggleButton value="present">Present</ToggleButton>
                    <ToggleButton value="absent">Absent</ToggleButton>
                    <ToggleButton value="unknown">Present but unmatched</ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
                <div style={{ display: "inline-block" }}>
                  <Tooltip title="Refresh reconciled list">
                    <MDButton onClick={handleRefresh} style={{ marginTop: 5 }}>
                      <RefreshIcon size="small" />
                    </MDButton>
                  </Tooltip>{" "}
                  {filterValue !== "unknown" && (
                    <Tooltip title="Send email">
                      <MDButton onClick={handleMailAll} style={{ marginTop: 5 }}>
                        <MailOutlineIcon size="small" />
                      </MDButton>
                    </Tooltip>
                  )}
                  {filterValue !== "unknown" && " "}
                  {filterValue === "unknown" && (
                    <Tooltip title="Link Present But Unmatched">
                      <MDButton
                        onClick={handleLinkAction}
                        style={{ marginTop: 5 }}
                        disabled={reconciledData?.length === 0}
                      >
                        <LinkIcon size="small" />
                      </MDButton>
                    </Tooltip>
                  )}
                  {filterValue === "unknown" && " "}
                  <Tooltip title="Export list">
                    <MDButton
                      onClick={handleExport}
                      style={{ marginTop: 5 }}
                      disabled={reconciledData?.length <= 0 || !selectedMeet}
                      color={sidenavColor}
                    >
                      <SaveIcon size="small" />
                    </MDButton>
                  </Tooltip>
                </div>
              </MDBox>
              <MDBox mb={3}>
                <UDList
                  ref={reconListCmp}
                  rows={reconciledData}
                  kind="present"
                  withCheckbox={filterValue !== "unknown"}
                  useAction={(row) => {
                    return (
                      filterValue === "present" &&
                      row?.is && (
                        <MDButton
                          onClick={() => {
                            if (filterValue === "present" && row?.is) {
                              handleUnlinkAction(row);
                            }
                          }}
                        >
                          {filterValue === "present" && <LinkOffIcon size="small" />}
                        </MDButton>
                      )
                    );
                  }}
                  setAllSelected={setAllSelectedMails}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <CalendarModal
        setSession={handleSelectedMeet}
        openModal={openCalendarModal}
        setOpenModal={setOpenCalendarModal}
      />
      <EnrolledModal
        ref={enrolledModalCmp}
        setSelectedSheet={setSelectedSheet}
        setSelectedSheetSheet={setSelectedSheetSheet}
        setSheetRows={setSheetRows}
        setEnrolledData={setEnrolledData}
        openModal={openEnrolledModal}
        setOpenModal={setOpenEnrolledModal}
      />
      <LinkModal
        meetId={meetId}
        updateData={handleLink}
        items={reconciledData}
        linkedItems={linkedItems}
        absentees={absenteeData}
        openModal={openLinkModal}
        setOpenModal={setOpenLinkModal}
      />
      <ExportModal
        reconciled={reconciledData}
        attendees={attendeeData}
        exportAttendees={exportAttendees}
        setExportAttendees={setExportAttendees}
        currentMeet={selectedMeet}
        openModal={openExportModal}
        setOpenModal={setOpenExportModal}
        filterType={filterValue}
        startTime={sessionStartTime}
        endTime={sessionEndTime}
      />
      <MeetModal
        setSession={handleSelectedMeet}
        openModal={openMeetModal}
        setOpenModal={setOpenMeetModal}
      />
      <MailModal
        recipients={allSelectedMails}
        openModal={openMailModal}
        setOpenModal={setOpenMailModal}
        clearSelected={reconListCmp.current?.unselectAll}
        selectedSender={selectedSender}
        meetId={meetId}
      />
    </DashboardLayout>
  );
}

export default Dashboard;
