import { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import {
  Box,
  Grid,
  Modal,
  FormControl,
  MenuItem,
  Autocomplete,
  TextField,
  Badge,
  CircularProgress,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { DatePicker, PickersDay, TimeField, SingleInputTimeRangeField } from "@mui/x-date-pickers";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import moment from "moment";
import { useMaterialUIController } from "context";
import { loginCouchDb, getCalendar, getDocumentById } from "lib/couchDb";

function MeetDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  const dayExists = highlightedDays.filter((d) => d.isSame(day, "day"));
  const isSelected = !props.outsideCurrentMonth && dayExists.length > 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? "📌" : undefined}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
}

MeetDay.propTypes = {
  highlightedDays: PropTypes.array,
  day: PropTypes.any,
  outsideCurrentMonth: PropTypes.any,
  other: PropTypes.any,
};

function MeetModal({ setSession, openModal, setOpenModal }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [meetSessions, setMeetSessions] = useState([]);
  const [filteredMeetSessions, setFilteredMeetSessions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMeet, setSelectedMeet] = useState(null);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [minStartTime, setMinStartTime] = useState(null);
  const [maxEndTime, setMaxEndTime] = useState(null);
  const [timeDisabled, setTimeDisabled] = useState(true);
  // const [rangeError, setRangeError] = useState(false);
  const [okDisabled, setOkDisabled] = useState(true);
  const [refreshDisabled, setRefreshDisabled] = useState(true);
  const [sliced, setSliced] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getHighlightedDays = (rows) => {
    // const dates = rows.map((row) => moment(row.startTime));
    const dates = rows.map((row) => moment(row));

    setHighlightedDays(dates);
  };

  const filterMeets = (sessions, date) => {
    return sessions[date.format("YYYY/MM/DD")] || [];
  };

  const getMeetSessions = async () => {
    setIsLoading(true);
    await loginCouchDb();
    // const rows = await getDocuments();
    const calendar = await getCalendar();
    const meets = [];
    const dates = [];

    // if (rows && rows.length > 0) {
    if (calendar) {
      const entries = Object.entries(calendar);

      for (const [date, dayMeets] of entries) {
        meets.push(
          ...dayMeets.map((m) => {
            m.label = m.name;

            return m;
          })
        );
        dates.push(date);
      }
      // rows.forEach((item) => {
      //   const session = item.value;

      //   session.label = session.name;
      //   meets.push(session);
      // });

      debugger;
      const today = moment();
      const filtered = filterMeets(calendar, today);

      setSelectedDate(today);
      setMeetSessions(calendar);
      setFilteredMeetSessions(filtered);
      getHighlightedDays(dates);
      setIsLoading(false);

      return meets;
    }

    setIsLoading(false);

    return [];
  };

  const handleDateChange = async (value) => {
    setSelectedDate(value);
    setSelectedMeet(null);
    setStartTime(null);
    setEndTime(null);
    setRefreshDisabled(true);
    setOkDisabled(true);

    if (value) {
      // const meets = await getMeetSessions();
      // const filtered = meets.filter((item) => {
      //   const startDate = moment(item.startTime).format("MM/DD/YYYY");
      //   const selected = value.format("MM/DD/YYYY");

      //   return selected === startDate;
      // });
      const filtered = filterMeets(meetSessions, value);

      if (selectedMeet) {
        const exists = filtered.filter((row) => row.name === selectedMeet.name);

        if (exists.length == 0) setSelectedMeet(null);
      }

      setFilteredMeetSessions(filtered);
    }
  };

  const handleMeetChange = (e, value) => {
    setSelectedMeet(value);
    setTimeDisabled(false);
    // setSelectedDate(null);

    if (value) {
      // handleDateChange(moment(value.startTime));
      handleResetTimes(null, value);
      setOkDisabled(false);
      setRefreshDisabled(false);
    } else {
      // handleDateChange(null);
      setStartTime(null);
      setEndTime(null);
      setTimeDisabled(true);
      setOkDisabled(true);
      setRefreshDisabled(true);
    }
  };

  const handleTimeChange = (value) => {
    console.log(value);
  };

  const handleStartTimeChange = (value) => {
    setStartTime(value);
    setSliced(true);
  };

  const handleEndTimeChange = (value) => {
    setEndTime(value);
    setSliced(true);
  };

  const handleResetTimes = async (e, meet = null) => {
    const theMeet = meet || (await getDocumentById(selectedMeet._id));

    const startTime = moment(theMeet.startTime);
    let endTime = null;

    if (theMeet.endTime) {
      endTime = moment(theMeet.endTime);
    } else {
      endTime = moment(theMeet.startTime).add(theMeet.totalSeconds, "seconds");
    }

    setStartTime(startTime);
    setMinStartTime(startTime);
    setEndTime(endTime);
    setMaxEndTime(endTime);
    setSliced(false);
  };

  const handleResetForm = async () => {
    setSelectedDate(null);
    setSelectedMeet(null);
    setStartTime(null);
    setEndTime(null);
    setTimeDisabled(true);
    setOkDisabled(true);
    setRefreshDisabled(true);
    setIsLoading(false);
    await getMeetSessions();
    // setFilteredMeetSessions(meetSessions);
  };

  const handleSelectMeet = async (e) => {
    const doc = await getDocumentById(selectedMeet._id);

    setSession(doc, startTime, endTime, sliced);
    setOpenModal(false);
  };

  useEffect(() => {
    getMeetSessions();
  }, []);

  return (
    <Modal
      open={openModal}
      onClose={() => {
        handleResetForm();
        setOpenModal(false);
      }}
      aria-labelledby="modal-meet-title"
      aria-describedby="modal-meet-escription"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: "15px",
        }}
      >
        <MDBox mb={1}>
          <MDTypography id="modal-meet-title" variant="h6" component="h2" fontWeight="medium">
            Select a Meet
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <FormControl sx={{ width: "100%" }}>
                <DatePicker
                  value={selectedDate}
                  onChange={handleDateChange}
                  slots={{ day: MeetDay }}
                  slotProps={{
                    textField: { size: "medium" },
                    day: {
                      highlightedDays,
                    },
                  }}
                  label="Meet Date"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Autocomplete
                disablePortal
                options={filteredMeetSessions}
                value={selectedMeet}
                onChange={handleMeetChange}
                renderInput={(params) => <TextField {...params} label="Meet Name" />}
              />
            </Grid>
            {/* <Grid item xs={12} md={8} lg={8}>
              <FormControl sx={{ width: "100%" }}>
                <SingleInputTimeRangeField
                  label="Start - End time"
                  value={[startTime, endTime]}
                  onChange={handleTimeChange}
                  onError={(err) => setRangeError(true)}
                  format="HH:mm"
                  disabled={timeDisabled}
                  minTime={minStartTime}
                  maxTime={maxEndTime}
                />
              </FormControl>
            </Grid> */}
            <Grid item xs={12} md={4} lg={4}>
              <FormControl sx={{ width: "100%" }}>
                <TimeField
                  label="Start time"
                  value={startTime}
                  onChange={handleStartTimeChange}
                  // onError={(err) => setRangeError(true)}
                  format="HH:mm"
                  disabled={timeDisabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FormControl sx={{ width: "100%" }}>
                <TimeField
                  label="End time"
                  value={endTime}
                  onChange={handleEndTimeChange}
                  // onError={(err) => setRangeError(true)}
                  format="HH:mm"
                  disabled={timeDisabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDButton
                onClick={handleResetTimes}
                style={{ marginTop: 2, width: "100%" }}
                disabled={timeDisabled || refreshDisabled}
                color="secondary"
              >
                <ReplayOutlinedIcon size="medium" />
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <MDButton
              component="button"
              onClick={handleResetForm}
              variant="gradient"
              color="primary"
              fullWidth
            >
              Clear
            </MDButton>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDButton
              component="button"
              onClick={() => {
                // handleResetForm();
                setOpenModal(false);
              }}
              variant="gradient"
              color="secondary"
              fullWidth
            >
              Cancel
            </MDButton>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDButton
              component="button"
              onClick={handleSelectMeet}
              variant="gradient"
              color={sidenavColor}
              fullWidth
              disabled={okDisabled}
            >
              OK
            </MDButton>
          </Grid>
        </Grid>
        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              color: "green",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    </Modal>
  );
}

MeetModal.propTypes = {
  setSession: PropTypes.func,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};

export { MeetModal };
