import { PropTypes } from "prop-types";
import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Checkbox,
  ListItemIcon,
  ListSubheader,
  Typography,
} from "@mui/material";
import MDTypography from "components/MDTypography";

const UDList = forwardRef(
  ({ rows, withCheckbox, useAction, setAllSelected, kind = "no-time", maxTimeSlice = 0 }, ref) => {
    const theList = useRef();
    const [checked, setChecked] = useState([0]);
    const [allChecked, setAllChecked] = useState(false);
    const textColor = rows.length > 0 ? "black" : "white";

    useImperativeHandle(ref, () => ({
      unselectAll() {
        setChecked([0]);
        setAllChecked(false);
      },
    }));

    const formatDiffTime = (diff) => {
      let tmp = diff;

      if (diff > maxTimeSlice) tmp = maxTimeSlice;

      const totalMinutes = parseInt(Math.floor(tmp / 60), 0);
      let seconds = parseInt(tmp % 60, 0);
      let minutes = parseInt(Math.floor(totalMinutes % 60), 0);
      let hours = parseInt(Math.floor(totalMinutes / 60), 0);

      if (seconds >= 30) minutes++;
      if (minutes == 60) {
        minutes = 0;
        hours++;
      }

      seconds = 0;
      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      return `${hours}h ${minutes}m`; //:${seconds}`;
    };

    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
      const selected = [];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      newChecked.forEach((key) => {
        if (key === 0 || key === "") return;

        const el = document.querySelector(`li#${key}`);

        if (el && el.dataset?.item) {
          selected.push(JSON.parse(el.dataset?.item));
        }
      });

      if (setAllSelected) setAllSelected(selected);
      setChecked(newChecked);
    };

    const handleSelectAll = () => {
      const newValue = !allChecked;
      const selected = [];

      if (newValue) {
        const newChecked = [...checked];

        Array.prototype.slice.call(theList.current.children).forEach((el) => {
          const currentIndex = checked.indexOf(el.id);
          const itemStr = el.dataset?.item;

          if (currentIndex === -1) {
            newChecked.push(el.id);
          }

          if (itemStr) selected.push(JSON.parse(itemStr));
        });

        setChecked(newChecked);
      } else {
        setChecked([0]);
      }

      setAllSelected(selected);
      setAllChecked(newValue);
    };

    const formatSecondary = (row) => {
      const cnt = [];
      const secondLine = [];

      if (kind !== "time" && row.email) cnt.push(row.email);
      if (kind === "time" && row.blocksSeconds) cnt.push(formatDiffTime(row?.blocksSeconds));
      if (kind !== "time" && row.company) {
        if (row.phone && row.company.length + row.phone.length >= 29) {
          let max = 29 - row.phone.length - 7;

          if (max <= 0) max = 5;

          secondLine.push(`${row.company.substr(0, max).trim()} ...`);
        } else secondLine.push(row.company);
      }
      if (kind !== "time" && row.phone) secondLine.push(row.phone);
      if (secondLine.length > 0) {
        if (kind === "present" && row?.is)
          return (
            <Typography variant="body2" style={{ color: "red" }}>
              {cnt.join(" - ")}
              <br />
              {secondLine.join(" | ")}
            </Typography>
          );
        else
          return (
            <>
              {cnt.join(" - ")}
              <br />
              {secondLine.join(" | ")}
            </>
          );
      } else {
        if (kind === "present" && row?.is)
          return (
            <Typography variant="body2" style={{ color: "red" }}>
              {cnt.join(" - ")}
            </Typography>
          );
        else return cnt.join(" - ");
      }
    };

    return (
      <List
        ref={theList}
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          borderRadius: "0.75rem",
          maxHeight: "735px",
          overflow: "auto",
        }}
      >
        <ListSubheader
          sx={{
            borderBottom: "0.0625rem solid #f0f2f5",
            borderTopLeftRadius: "0.75rem",
            borderTopRightRadius: "0.75rem",
          }}
        >
          {withCheckbox && (
            <Checkbox edge="start" checked={allChecked} disableRipple onChange={handleSelectAll} />
          )}
          <MDTypography
            variant="span"
            style={{
              opacity: 0.7,
              color: textColor,
              fontSize: "0.875rem",
              fontWeight: 700,
              marginLeft: withCheckbox ? "30px" : "0px",
            }}
          >
            {rows.length > 0 ? `Total rows: ${rows.length}` : " ... "}
          </MDTypography>
        </ListSubheader>
        {rows &&
          rows
            ?.sort((a, b) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

              return 0;
            })
            // .filter((row) => {
            //   if (kind === "time") {
            //     return row?.blocksSeconds > 59;
            //   }

            //   return true;
            // })
            .map((row, idx) => {
              const key = `ID_${row.name.replace(/[ '\+\(\)]/g, "")}_${idx}`;
              const labelId = `checkbox-list-label-${key}`;

              if (kind === "time" && row?.blocksSeconds <= 29) {
                row.blocksSeconds = 59;
              }

              return (
                <ListItem
                  id={key}
                  key={key}
                  alignItems="flex-start"
                  secondaryAction={useAction ? useAction(row) : null}
                  sx={{ borderBottom: "0.0625rem solid #f0f2f5" }}
                  data-item={JSON.stringify(row)}
                >
                  <ListItemButton role={undefined} onClick={handleToggle(key)} dense>
                    {withCheckbox && (
                      <ListItemIcon>
                        <Checkbox
                          key={`_${key}`}
                          edge="start"
                          checked={checked.indexOf(key) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </ListItemIcon>
                    )}
                    <ListItemAvatar>
                      <Avatar alt={row.name} src={row.picture} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        kind === "present" && row?.is ? (
                          <Typography variant="body2" style={{ color: "red" }}>
                            {row.name} ({row.is.name})
                          </Typography>
                        ) : (
                          row.name
                        )
                      }
                      secondary={formatSecondary(row)}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
      </List>
    );
  }
);

UDList.propTypes = {
  rows: PropTypes.array.isRequired,
  withCheckbox: PropTypes.bool,
  useAction: PropTypes.func,
  setAllSelected: PropTypes.func,
  kind: PropTypes.string,
  maxTimeSlice: PropTypes.number,
};

export { UDList };
